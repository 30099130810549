// TODO this is hacked quickly for a trail
import SaController from './sa_controller';
import '@uppy/core/dist/style.css'

import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import FileInput from '@uppy/file-input'
import XHRUpload from '@uppy/xhr-upload'


export default class extends SaController {
  static targets = ['input', 'trigger', 'attachments','error','loading']
  static values = {
    options: Object,
    presigned: String
  }

  get secured() {
    return this.element.getAttribute('data-secure-uploads') == 'true'
  }

  get uppyOptions() {
    return Object.assign(
      { autoProceed: true },
      this.optionsValue
    )
  }

  connect() {
    this.inputTarget.addEventListener('change', this.onInputTargetChange)
    this.triggerTarget.addEventListener('click', this.onTriggerClicked)

    this.uppy = new Uppy(this.uppyOptions)
    this.log("Options: ", this.uppyOptions)

    this.uppy.use(AwsS3, { getUploadParameters: this.getUploadParameters })
    this.uppy.on('files-added', this.uppyFilesAdded)
    this.uppy.on('upload-progress', this.uppyUploadProgress)
    this.uppy.on('upload-success', this.uppyUploadSuccess)
    this.uppy.on('complete', this.uppyComplete)
  }

  disconnect() {
    this.inputTarget.removeEventListener('change', this.onInputTargetChange)
    this.triggerTarget.removeEventListener('click', this.onTriggerClicked)
    this.uppy.close()
  }

  uppyUploadProgress = (file, progress) => {
    this.log('uppyUploadProgress - file', file);
    this.log('uppyUploadProgress - progress', progress);
    const val = Math.floor(parseFloat(progress.bytesUploaded / progress.bytesTotal) * 100).toString()
    this.element.querySelector(`[data-key="${file.id}"] > progress`).setAttribute('value', val);
  }


  uppyUploadSuccess = (file, data) => {
    this.log('uppyUploadSuccess - file', file)
    this.log('uppyUploadSuccess - data', data)

    this.loadingTarget.innerHTML = '';
  }

  onRemoveFile = (event) => {
    this.log('onRemoveFile - event', event)
    event.target.removeEventListener('click', this.onRemoveFile)

    const div = event.target.closest('div')
    this.uppy.getFiles()
             .filter(f => f.id == event.target.dataset.key)
             .forEach(f => this.uppy.removeFile(f.id))

    div.remove()
  }

  updateInternalFileState = () => {
    this.attachmentsTarget.value = this.uppy.getFiles().map(f => f.meta.asset.id)
  }

  uppyFilesAdded = (files) => {
    this.log('uppyFilesAdded - files added', files)
    files.forEach(file => {
      this.loadingTarget.innerHTML += `
      <div data-key="${file.id}" class="w-full mt-2 p-2">
        <div>${file.name}</div>
        <progress class="progress flex-1" value="0" max="100"></progress>
      </div>`;
    })
  }

  uppyComplete = (event) => {
    this.updateInternalFileState();
    this.element.closest("form").requestSubmit();

    this.inputTarget.value = null
  }
 
  onTriggerClicked = (event) => {
    this.inputTarget.click()
    event.preventDefault();
    event.stopPropagation();
  }

  onInputTargetChange = (event) => {
    const files = Array.from(event.target.files)
    this.log('onInputTargetChange - files', files)

    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          this.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    })
  }

  getUploadParameters = (file) => {
    return  this.post(this.presignedValue, { name: file.name, content_type: file.type, size: file.size, secured: this.secured })
                .then((response) => {
                  return response.json()
                }).then((data) => {

                  this.uppy.setFileMeta(file.id, Object.assign(file.meta, { asset: data.result.asset }))

                  return {
                    method: 'POST',
                    url: data.result.url,
                    fields: data.result.fields,
                    headers: {},
                  }
                })
  }

  log = (message, args) => {
    console.log(message, args)
  }

  toggleError = (force) => {
    if (this.hasErrorTarget) {
      this.errorTarget.classList.toggle("hidden", force);
    }
  }

// eof
}
