import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ 'content' ]

  connect() {
  }

  copy() {
    navigator.clipboard.writeText(this.contentTarget.value);
  }
  
}
