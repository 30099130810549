import SaController from './sa_controller';

export default class extends SaController {

  connect() {
    this.element.addEventListener('click', this.clicked);

    if (this.element.hasAttribute('data-clear-frame-on-close')) {      
      this.drawerCheckbox.addEventListener('change', this.clearTurboFrame);
    }
  }

  clearTurboFrame = (event) => {
    if (!this.drawerCheckbox.checked && this.turboFrame.innerHTML) {
      this.turboFrame.innerHTML = '<div class="flex h-full items-center p-4"><span class="mx-auto text-primary-content text-xl font-bold">Loading...</span></div>'
    }
  }

  clicked = (event) => {
    this.drawerCheckbox.checked = !this.drawerCheckbox.checked
    if(this.element.dataset.swapDrawer){
      document.getElementById(this.element.dataset.swapDrawer).checked = false;
    }
  }

  get turboFrame() {
    return document.getElementById(this.element.dataset.turboFrame)
  }

  get drawerCheckbox() {
    return document.getElementById(this.element.dataset.drawer);
  }

  disconnect() {
    this.drawerCheckbox.removeEventListener('change', this.clearTurboFrame);
  }
}
