import SaController from './sa_controller';

export default class extends SaController {
  static values = {
    environment: String
  }

  connect() {
    Array.from(this.element.querySelectorAll("a")).forEach(anchor => {
      anchor.addEventListener('click', this.alertExternalNav);
    })
  }

  alertExternalNav = (event) => {
    if(!confirm( `This link is external to ${this.environmentValue}, would you like to continue?`)) {
      event.stopPropagation();
      event.preventDefault();
    } 
  }

  disconnect() {
    Array.from(this.element.querySelectorAll("a")).forEach(anchor => {
      anchor.removeEventListener('click', this.alertExternalNav);
    })
  }
}

