import SaController from '../sa_controller';
import Listbox from './listbox';

export default class extends SaController {
  connect() {
    this.element.setAttribute('aria-activedescendant', this.element.querySelector("[role=option]").id);
    this.listbox = new Listbox(this.element);
  }

  disconnect() {
  }
}
