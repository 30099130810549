import SaController from './sa_controller';

export default class extends SaController {
  static targets = ['view', 'edit', 'focus', 'form'];

  connect() {
    console.log("OOO");
    this.viewTarget.classList.remove('hidden');
    this.editTarget.classList.add('hidden');
  }

  showEdit() {
    this.viewTarget.classList.add('hidden');
    this.editTarget.classList.remove('hidden');
    this.focusTarget.focus();
  }

  blur() {
    setTimeout(() => {
      this.cancel();
    }, 300);
  }

  cancel() {
    this.viewTarget.classList.remove('hidden');
    this.editTarget.classList.add('hidden');
  }

  submit() {
    this.formTarget.requestSubmit();
  }
}
