import SaController from './sa_controller';

export default class extends SaController {

  connect() {
    this.element.scrollIntoView();
  }

  disconnect() {
  }
}

