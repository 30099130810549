import SaController from '../sa_controller';
import '../../elements/progress_ring';
export default class extends SaController {
  static targets = ['preview', 'progress'];
  static values = { 
    asset: Object,
    progress: Number,
    successUrl: String
  };
  connect() {
    this.element.presignedUploadController = this;
  }
  progressValueChanged = () => {
    this.progressTarget.setAttribute('progress', this.progressValue * 100);
  }
  initFormData() {
    const formData = new FormData();
    const fields = this.assetValue['fields'];
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });
    return formData;
  }
  get url() {
    return this.assetValue['url'];
  }
  // Call the success url
  // expect it to return a stream to replace the
  // uploading asset with the final asset
  success = () => {
    if(this.successUrlValue) {
      // Post to success url
      this.post(this.successUrlValue, {assetId: this.assetValue['asset'].id})
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
  // Set an image preview while the file is uploading
  setPreview(file) {
    const reader = new FileReader();
    reader.onload = (ev) => {
      this.previewTarget.src = ev.target.result;
    };
    reader.readAsDataURL(file);
  }
  startUpload(file) {
    const formData = this.initFormData();
    formData.append("file", file);
    this.setPreview(file);
    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (ev) => { this.progressValue = ev.loaded/ev.total; }
    xhr.open("POST", this.url, true);
    xhr.send(formData);
    xhr.onload = (evt) => {
      if(xhr.status === 201) {
        console.log("Upload complete");
        this.success();
      } else {
        console.log("Upload failed");
      }
    };
  }
}
