import SaController from './sa_controller';

export default class extends SaController {
  static targets = [ "brandPicker","notificationItem", "user" ]

  async connect() {
    // sa-select event
    this.brandPickerTarget.addEventListener('update', this.brandPicked.bind(this));
    // sa-autocomplete event
    this.brandPickerTarget.addEventListener('selectionChanged', this.brandPicked.bind(this));

    this.notificationItemTarget.addEventListener('click', this.showNotifications.bind(this));

    const notificationStream = document.querySelector("#notifications > turbo-cable-stream-source");
    const channel = notificationStream.channel

    if(window.cable) {
      this.subscription = await cable.subscribeTo(channel, { received: this.updateNotifications.bind(this)})
    }
  }

  disconnect() {
    if (this.subscription) this.subscription.unsubscribe()
  }

  fireCSATAfterCompose() {
    document.addEventListener("close_compose", () => {
      clearTimeout(window.csatTimeout);
      window.csatTimeout = setTimeout(() => {
        document.querySelector("sa-csat").open("How was your experience with Compose today?", "compose");
      }, 350)
    });
  }

  showNotifications(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    document.querySelector("#notification-panel").classList.add("active");
    this.userTarget.close();
  }

  updateNotifications(evt) {
    const counter = this.notificationItemTarget.querySelector("span");
    this.userTarget.classList.add('show-badge');

    counter.classList.remove('invisible');
    counter.classList.add('visible');

    const inner = counter.innerText;
    let count = 1;
    try {
      count = parseInt(inner)  + 1;
    } catch {
    }
    counter.innerText = count.toString();
  }

  async brandPicked(evt) {
    let brandId = null;
    if(evt.target.matches("sa-select")) {
      brandId = evt.detail;
    } else {
      brandId = evt.detail.value;
    }

    if (brandId && brandId != document.querySelector("body").dataset.brand) {

      // Request change 
      let resp = await this.post("/switch_brand", {brand_id: brandId });

      // Ensure we don't end up somewhere we don't belong
      if(window.location.pathname.match("^/compose")) {
        window.location = "/";
      } else if( window.location.pathname.match("^/management")) {
        window.location = "/management/settings";
      } else if (window.location.pathname.match("^/community")){
        window.location = "/";
      } else {
        window.location = window.location.protocol + "//" + window.location.host + window.location.pathname;
      }
    }
  }
}

