import SaController from './sa_controller';
import twitter from 'twitter-text';

export default class extends SaController {
  static values = {
    entities: Object
  }

  connect() {
    if(this.entitiesValue) {
      this.element.innerHTML = twitter.autoLink(this.element.textContent, {urlEntities: this.entitiesValue.urls, targetBlank: true});
    } else {
      this.element.innerHTML = twitter.autoLink(this.element.textContent, {targetBlank: true});
    }
  }

  disconnect() {
  }
}
