import {cable, Turbo} from "@hotwired/turbo-rails"
import '@github/time-elements';

window.cable = cable;
window.Turbo = Turbo;

import './controllers'
import './style/tailwind.css'
import './lib/notification'

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

import { StreamActions } from "@hotwired/turbo"
 
StreamActions.update_stimulus_value = function() {
  const elementId = this.getAttribute("target");
  const key = this.getAttribute("key");
  const value = this.getAttribute("value");

  const elem = document.getElementById(elementId);
  elem.setAttribute(key, value);
}

import TurboSamorph from './lib/samorph'
TurboSamorph.initialize(Turbo.StreamActions)

import MobileHeightCalculator from './lib/mobile_height_calculator'
MobileHeightCalculator.initialize()

setTimeout(() => {
  Stimulus.debug = true; //window.IS_DEV_ENV;
 
  // Single we have loaded the application js
  window.loaded = true;
  const event = new CustomEvent('application:loaded');
  document.dispatchEvent(event);
});

document.addEventListener("turbo:load", function() {
  document.querySelectorAll('.modal-toggle').forEach((modal) => {
    modal.checked = false;
  })
})
