import ComposeController from '../compose_controller';
import '../../elements/progress_ring';

export default class extends ComposeController {
  static targets = ['file', 'asset', 'assetArea'];
  static classes = ['upload'];

  connect() {
    this.previews = {}
  }

  disconnect() {
  }

  uploadFile = (event) => {
    this.fileTarget.click();
  }

  assetTargetConnected(element) {
    const assetId = element.assetController.idValue;
    const preview = this.composeManager.getPreview(assetId);
    if(preview) {
      element.assetController.setPreview(preview);
    }
  }

  createAssets = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      this.composeManager.requestAsset(file, asset => this.takeAction(
        'attach_asset', 
        { asset_id: asset.id },
      ))
    })
  }
}
