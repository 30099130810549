import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['on','off','focusOn','focusOff'];

  static values = {
    on: Boolean,
    focusOn: String,
    focusOff: String
  }

  connect() {
    this.onTarget.classList.add('hidden')
    if(this.onValue) {
      this.toggle();
    }
  }

  onValueChanged() {
    if(this.on != this.onValue) {
      this.toggle()
    }
  }

  get on() {
    return this.offTarget.classList.contains('hidden');
  }

  toggle() {
    this.onTargets.forEach(elem => elem.classList.toggle('hidden'));
    this.offTargets.forEach(elem => elem.classList.toggle('hidden'));

    setTimeout(() => {
      if(this.on && this.focusOnValue) {
        this.element.querySelector(this.focusOnValue).focus();
        this.element.querySelector(this.focusOnValue).click();
      } 
      
      if(this.on && this.hasFocusOnTarget) {
        this.focusOnTarget.focus();
        this.focusOnTarget.click();
      } 

      if(!this.on && this.focusOffValue) {
        this.element.querySelector(this.focusOffValue).focus();
        this.element.querySelector(this.focusOffValue).click();
      } 

      if(!this.on && this.hasFocusOffTarget) {
        this.focusOffTarget.focus();
        this.focusOffTarget.click();
      } 
    });
  }
}

