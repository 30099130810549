import { capitalize } from 'lodash';

class NetworksList extends HTMLElement {
  constructor() {
    super();
    setTimeout(() => { this.render(); });
  }

  get networks() {
    if(this.getAttribute('networks')) {
      return this.getAttribute('networks').split(',');
    } else {
      return [];
    }
  }

  render() {
    if(this.networks.length > 0) {
      // Reverse because otherwise tooltip falls behind the next element
      this.innerHTML = `<div class="flex space-x-2 space-x-reverse flex-row-reverse">${this.networks.map(network => this.networkBadge(network)).join('')}<div>`;
    } else {
      this.innerHTML = `<div class="flex space-x-2 space-x-reverse flex-row-reverse">${this.networkBadge('none', 'bg-error')}<div>`;
    }
  }

  networkBadge(network, bg) {
    if(network == 'twitter')
      return `<div class="${bg || 'bg-base-100'} bg-opacity-50 w-6 h-6 rounded-full justify-center items-center tooltip tooltip-right" data-tip="Publishing to X}">
          <div class="m-1 z-0">
            <img src="https://socialassurance.s3.amazonaws.com/logos/logo-x.svg">
          </div>
        </div>`
    else
      return `<div class="${bg || 'bg-base-100'} bg-opacity-50 w-6 h-6 rounded-full justify-center items-center tooltip tooltip-right" data-tip="Publishing to ${capitalize(network)}">
          <div class="m-1 z-0">
            <img src="https://socialassurance.s3.amazonaws.com/logos/logo-${network}.svg">
          </div>
        </div>`
  }


  static get observedAttributes() {
    return [ 'networks', 't' ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    setTimeout(() => { this.render(); });
  }
}

window.customElements.define('networks-list', NetworksList);
