import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.addEventListener('load', this.restrict)
    this.restrict();
  }

  disconnect() {
    this.element.removeEventListener('load', this.restrict)
  }

  get minLimit() {
    return Number(this.element.dataset.min);
  }

  get maxLimit() {
    return Number(this.element.dataset.max);
  }

  restrict = () => {
    const image = this.element
    const ratio = image.naturalWidth / image.naturalHeight

    console.log(this.element.dataset); 
    console.log("RATIO", ratio, this.minLimit, this.maxLimit); 
    if (ratio < this.minLimit) {
      image.style.aspectRatio = this.minLimit;
    } else if (ratio > this.maxLimit) {
      image.style.aspectRatio = this.maxLimit;
    }
  }
}


