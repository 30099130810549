import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.createObserver()
  }

  createObserver() {
    const observer = new IntersectionObserver(
      entries => this.handleIntersect(entries),
      {
        threshold: [0, 1],
      }
    )
    observer.observe(this.element);
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.element.click()
      }
    })
  }
}
