import { Application } from "@hotwired/stimulus"
import Sortable from 'stimulus-sortable'
import TextareaAutogrow from 'stimulus-textarea-autogrow'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application
//application.register('sortable', Sortable)
application.register('textarea-autogrow', TextareaAutogrow)

export { application }
