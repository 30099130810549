import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    hrefBase: String,
    ids: Array
  };

  static targets = ['content'];

  connect() {
    this.element.addEventListener('focus', this.focus);
    this.element.addEventListener('blur', this.blur);
  }

  focus = () => {
    this.popup.classList.remove("hidden");
    this.popup.classList.add("absolute");
  }

  blur = () => {
    this.popup.classList.add("hidden");
    this.popup.classList.remove("absolute");
  }

  addFrames = (popup) => {
    this.idsValue.forEach(id => {
      let tf = document.createElement('turbo-frame');
      tf.src = `${this.hrefBaseValue}/${id}` 
      tf.id = id;
      tf.textContent = "LOADING";
      this.popup.append(tf);
    });
  }

  get popup() {
    let popup = this.element.querySelector('div.popup')
    if(!popup) {
      popup = document.createElement('div');
      popup.classList.add("popup", "mt-2", "w-48", "flex", "flex-col", "space-y-2", "bg-base-100", "z-10")
      this.element.append(popup);
      this.addFrames(popup);
    }
    return popup;
  }

  disconnect() {
    this.element.removeEventListener('focus', this.focus);
    this.element.removeEventListener('blur', this.blur);
  }
}


