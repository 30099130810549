import { UIEvent, PhotoEditorSDKUI, ImageFormat, ExportFormat } from 'photoeditorsdk';

ImageEditor = {

  initialize: function() {
    this.getStickers();
    this.layout = (window.innerWidth > 600) ? 'advanced' : 'basic';
  },

  getStickers: function() {
    fetch("/users/stickers.json").then(async (resp) => {
      this.stickers = (await resp.json()).sticker;
    })
  },

  defaultOptions: function(url) {
    return {
      layout: this.layout,
      defaultTool: 'transform',
      assetBaseUrl: "https://assets.socialassurance.com/pesdk/assets",
      container: '#editor',
      image: url,
      custom: {
        languages: {
          en: {
            mainCanvasActions: {
              buttonExport: 'Save',
            },
          },
        },
      },
      sticker: this.stickers,
      export: {
        image: {
          enableDownload: false,
          format: ImageFormat.PNG,
          exportType: ExportFormat.DATA_URL,
        },
      },
      license: '{"api_token":"xSjqEhpqZA5geAyMIpSrjw","app_identifiers":["localhost","*.socialassurance.com","*.classintercom.com"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","brush","filter","focus","frame","overlay","sticker","text","transform"],"issued_at":1569249620,"minimum_sdk_version":"1.0","owner":"Social Assurance","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"Kv5hNGkMwUoePimKbl3orgc+6iZHkDVGjtpL0sJCfB+ssdHW8cyTxYtKQow1I6wwpOgcODYvl+JKoGfELOr1QFMS/maG4QfjprNNVvlQk9re26eUd7Fbbx095Aaz9lw3PT5w5Vmn124PeCh5l4qtVnbzOdEVVk08vby+Q6xT/Nb1WI3D0yiEWQlmsz/SRo8UWxFF4I2XNnmAUPoe84yyHJjjoy2lcZY+9q4JuORAkQm9+q8/KJx/Q+zvZmIu0SUmIfVxdzQjPS1ax6Gu5KwOzeiHMe9RdVRsI+1Ji1st5iYidwj28atDAv0zSU3E0dh4bMb/7PUQkpXeMB+03+YADH5+JlOyQCH6lNmEnJe+HS87//cp7x4Ihd+LZMDlqQI7J58yiqgt1JGlMLwOOsAdqGDzbyuTaIJ1GUQkLtAwB9mzierVVOXArcRFvZ+QrsVarTRE1VnfqYiiaFXIM61IVqHyS7mNXQ2bJwVznDWt0h3azBECPb3ZJs/uOflVtCQ4db+6ARt10wCQf4nKUH0hC8tl70ztsbDFpfUaifb1UTe7DrUcHZWKvwJQJQQaax47s6b/YF4HQABhwsZeF2MvSGf5GXLXKO/j/gTlIQvCgqgQMfeOtxu/cRsM6AS0CFnaT3qV14EGSrQJePWwowhQ/5M6R/2kLm6GFNDPcwuFFv0="}', 
    };

  },

  crop: function(network) {
    const net = network ? network.toLowerCase() : false;

    if(net == 'instagram') {
      return {
        defaultTool: 'transform',
        transform: {
          categories: [
            {
              identifier: 'imgly_transforms_instagram',
              items: [
                { identifier: "imgly_transform_common_custom" },
                { identifier: 'imgly_transform_instagram_landscape' },
                { identifier: 'imgly_transform_instagram_portrait' },
                { identifier: 'imgly_transform_instagram_square' },
              ],
            }
          ]
        }
      };
    } else {
      return {};
    }
  },

  edit: async(url, crop) => {
    const defaultOptions = ImageEditor.defaultOptions(url);
    const cropOptions = ImageEditor.crop(crop);  
    const params = Object.assign(defaultOptions, cropOptions);

    const promise = PhotoEditorSDKUI.init(params);
    promise.then((editor) => {
      editor.setImage(url);
    });
    return promise;
  }
}

export default ImageEditor;
