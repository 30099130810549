import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.addEventListener('turbo:frame-render', this.frameRendered);
  }

  frameRendered = (event) => {
    if(!event.detail.fetchResponse.response.ok) {
      alert("Something went wrong");
    } else {
      const notice = event.detail.fetchResponse.response.headers.get("Flash-Notice");
      const _alert = event.detail.fetchResponse.response.headers.get("Flash-Alert");

      if(_alert) {
        alert(_alert);
      } else if(notice) {
        alert(notice);
      }
    }
  }

}
