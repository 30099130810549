import SaController from '../sa_controller';
import '../../elements/progress_ring';

export default class extends SaController {
  static targets = ['file', 'progress', 'image', 'assetId'];
  static classes = ['upload'];

  connect() {
    if(this.hasImageTarget) {
      this.imageTarget.addEventListener('error', this.emptyAvatar);
      this.imageTarget.addEventListener('load', this.successAvatar);
    }
    this.fileTarget.addEventListener('change', this.initiateUpload);
  }

  disconnect() {
    this.fileTarget.removeEventListener('change', this.initiateUpload);
  }

  uploadFile = (event) => {
    this.fileTarget.click();
  }

  imageDimensions = (url) => new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = url;
  })

  initiateUpload = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      let dimensions = {};

      if(file.type.split("/")[0] === 'image') {
        const url = URL.createObjectURL(file);
        if(this.hasImageTarget) {
          this.imageTarget.src = url;
        }
        dimensions  = await this.imageDimensions(url); 
      }

      this.post('/actions/create_presigned_asset', 
        { content_type: file.type,
          size: file.size,
          secured: false,
          name: file.name.replace("+", ""),
          width: dimensions.width,
          height: dimensions.height
        })
        .then(r => r.json())
        .then(json => this.doUpload(json.result.asset, file, json.result.url, json.result.fields));
    })
  }

  emptyAvatar = () => {
    this.imageTarget.classList.add("invisible")
  }

  successAvatar = () => {
    this.imageTarget.classList.remove("invisible")
  }


  emitStart = (asset) => {
    this.element.dispatchEvent(new CustomEvent('upload_start', {detail: {asset}, bubbles: true}));
  }

  emitProgress = (asset, progress) => {
    if(this.hasProgressTarget) {
      this.element.classList.add(this.uploadClass);
      this.progressTarget.classList.remove('hidden');
      this.progressTarget.setAttribute('progress', progress*100);
    }

    this.element.dispatchEvent(new CustomEvent('upload_progress', {
        detail: {asset, progress}, 
        bubbles: true
    }));
  }

  clearProgress() {
    if(this.hasProgressTarget) {
      this.element.classList.remove(this.uploadClass);
      this.progressTarget.classList.add('hidden');
      this.progressTarget.setAttribute('progress', 0);
    }
  }

  emitComplete = (asset) => {
    this.clearProgress();
    if(this.hasAssetIdTarget) {
      this.assetIdTarget.value = asset.id
      var event = new Event('change');
      this.assetIdTarget.dispatchEvent(event, {bubbles: true});
    }
    if(this.hasImageTarget) {
      this.imageTarget.src = asset.url;
    }

    this.element.dispatchEvent(new CustomEvent('upload_completed', {detail: {asset}, bubbles: true}));
  }

  emitFailure = (asset) => {
    this.clearProgress();
    this.element.dispatchEvent(new CustomEvent('upload_failed', {detail: {asset}, bubbles: true}));
  }

  doUpload = (asset, file, url, fields) => {
    const formData = new FormData();

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    // Actual file has to be appended last.
    formData.append("file", file);

    this.emitStart(asset);

    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (ev) => { this.emitProgress(asset, ev.loaded/ev.total) }
    xhr.open("POST", url, true);
    xhr.send(formData);
    xhr.onload = (evt) => {
      if(xhr.status === 201) {
        this.emitComplete(asset) 
      } else {
        this.emitFailure(asset)
      }
    };
  }
}



