import SaController from '../sa_controller';
import { debounce } from 'lodash';

/****************************************************
 Specific controller for the content calendar day 
                                                  
 Handles                                          
 - Hiding/Showing items based on row height       
 - Responding to resize
 
 navigateValue == false
   Expand cell to show all content for the day if 
   seemoreTarget clicked

 navigateValue == true
   Navigate to urlValue if seemoreTarget clicked

 ***************************************************/
export default class extends SaController {
  static targets = ['item','seemore', 'content','hideOnReveal','showOnReveal'];
  static classes = ['revealed','hide'];
  static values = {
    navigate: Boolean,
    url: String
  }

  connect() {
    if(this.navigateValue) {
      this.seemoreTarget.addEventListener('click', this.navigate);
    } else {
      this.seemoreTarget.addEventListener('focus', this.reveal);
      this.seemoreTarget.addEventListener('blur', this.hide);
    }

    // Watch resizing
    this.debouncedResized = debounce(this.resized, 300);
    new ResizeObserver(this.debouncedResized).observe(this.element)
  }

  // Getters
  get seemoreCount() {
    return this.itemTargets.filter(this.itemHidden).length;
  }

  // Actions
  navigate = () => {
    Turbo.visit(this.urlValue);
  }

  reveal = () => {
    if(this.revealing) return;

    // Hide See More but still focusable
    this.seemoreTarget.textContent = '';

    this.revealing = true;
    this.originalContentCls = this.contentTarget.className;
    this.contentTarget.className = '';

    this.originalElementCls = this.element.className;
    this.element.className = '';
    this.element.classList.add(...this.revealedClasses);

    this.hideOnRevealTargets.forEach(target => target.classList.add('hidden'));
    this.showOnRevealTargets.forEach(target => target.classList.remove('hidden'));

    this.adjustPosition();
  }

  hide = (evt) => {
    if(this.revealing && !this.element.contains(evt.relatedTarget)) {
      this.revealing = false;
      this.element.className = this.originalElementCls;
      this.contentTarget.className = this.originalContentCls;

      this.hideOnRevealTargets.forEach(target => target.classList.remove('hidden'));
      this.showOnRevealTargets.forEach(target => target.classList.add('hidden'));

      this.clearPosition();
    } else {
      evt.preventDefault();
      this.seemoreTarget.focus();
    }
  }

  adjustPosition = () => {
    const {left: _l, top: _t} = this.getOffset(this.element);
    const {offsetWidth: _w, offsetHeight: _h} = this.element;
    const {clientWidth: _cw, clientHeight: _ch} = document.documentElement;

    if(_ch > (_t + _h))  {
      this.element.style.top = "-20px";
    } else {
      this.element.style.top = `${(_ch - (_t + _h) - 20)}px`;
    }

    if(_cw < (_l + _w))  {
      this.element.style.right = '10px';
    }

  }

  clearPosition = () => {
    this.element.style.top = null;
    this.element.style.left = null;
  }

  resized = () => {
    this.showObscuredItems();
    this.seemoreTarget.textContent = '';
    if(!this.revealing && this.seemoreCount > 0) {
      this.seemoreTarget.textContent = `+${this.seemoreCount}`;
      this.hideObscuredItems();
    }
  }

  hideObscuredItems = () => {
    this.itemTargets.filter(this.itemHidden).forEach(item => item.classList.add('invisible'));
  }

  showObscuredItems = () => {
    this.itemTargets.forEach(item => item.classList.remove('invisible'));
  }

  itemHidden = (item) => {
    return item.parentElement.parentElement.offsetHeight < (item.offsetTop + item.offsetHeight);
  }

  getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    }
  }

  disconnect() {
    if(this.navigateValue) {
      this.seemoreTarget.removeEventListener('click', this.navigate);
    } else {
      this.seemoreTarget.removeEventListener('focus', this.reveal);
      this.seemoreTarget.removeEventListener('blur', this.hide);
    }
  }
}

