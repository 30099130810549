import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ 'container', 'trigger' ]
  static values = { open: Boolean, rowId: String, url: String, container: String, loaded: Boolean }

  async connect() {
    this.element.controller = this;
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  loadingRow() {
    const row = document.createElement('tr');
    const firstCell = document.createElement('td');
    const secondCell = document.createElement('td');
    const innerCell = document.createElement('div');
    const content = document.createElement('div');
    firstCell.classList.add('border-b');
    secondCell.classList.add('py-4', 'border-b');
    content.innerHTML = 'Loading...';
    innerCell.appendChild(content);
    secondCell.appendChild(innerCell);
    row.appendChild(firstCell);
    row.appendChild(secondCell);
    return row;
  }

  async openValueChanged() {
    console.log('openValueChanged', this.openValue);
    const container = document.getElementById(`${this.containerValue}`);
    if (!this.openValue && !this.loadedValue) {
      return;
    } else if (this.openValue && !this.loadedValue) {
      this.triggerTarget.children[0].classList.add('rotate-90');
      const rowAbove = container.querySelector(`#${this.rowIdValue}`);
      const rowBelow = this.loadingRow();
      rowAbove.after(rowBelow);
      await fetch(this.urlValue)
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html));
      rowBelow.remove();
      this.loadedValue = true;
    } else if (!this.openValue && this.loadedValue){
      this.triggerTarget.children[0].classList.remove('rotate-90');
      container.querySelectorAll(`[parent_id="${this.rowIdValue}"]`).forEach((element) => {
        element.classList.add('hidden');
      });
      container.querySelectorAll(`[remove_on_collapse="true"]`).forEach((element) => {
        element.remove();
      });
    } else {
      this.triggerTarget.children[0].classList.add('rotate-90');
      container.querySelectorAll(`[parent_id="${this.rowIdValue}"]`).forEach((element) => {
        element.classList.remove('hidden');
      })
    };
  }
}
