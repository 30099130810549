import SaController from './sa_controller';
import { debounce } from 'lodash';

/*
 * A Generic Form Controller
 *
 * Common functionality we want across forms
 *
 */
export default class extends SaController {
  static targets = [
    "showOnChange", "frameSrc", "enableOnInput",
    'dynamicRequiredFieldTrigger', 'dynamicRequiredField',
    "submitOnChange", "clearOnSubmit"
  ]

  static values = {
    'hasDynamicRequiredFields': Boolean
  }

  connect() {
    this.debouncedInputed = debounce(this.inputed, 500)

    this.element.addEventListener('change', this.changed);
    this.element.addEventListener('input', this.debouncedInputed);
    this.element.addEventListener('submit', this.onSubmit);
    if (this.element.dataset.focusOnLoad) {
      setTimeout(this.focus);
    }

    if (this.hasDynamicRequiredFieldsValue && this.hasDynamicRequiredFieldTriggerTarget) {
      this.dynamicRequiredFieldTriggerTarget.addEventListener('change', this.onDynamicRequiredFieldsTriggerChange)
    }
  }

  focus = () => {
    const x = this.element.querySelector(this.element.dataset.focusOnLoad);
    this.element.querySelector(this.element.dataset.focusOnLoad).focus();
  }

  inputed = (event) => {
    if (this.submitOnInput) {
      this.handleSubmit();
    }
    if (this.hasFrameSrcTarget) {
      this.updateSrc(this.frameSrcTarget);
    }
    if (this.hasEnableOnInputTarget) {
      this.enableOnInputTargets.forEach(elm => elm.disabled = false);
    }

  }

  updateSrc = (frameElement) => {
    frameElement.src = frameElement.dataset.basePath;
  }

  submit() {
    Turbo.navigator.submitForm(this.element);
  }

  changed = (event) => {
    if (this.submitOnChange) {
      this.handleSubmit();
    }
    if(this.hasSubmitOnChangeTarget && this.submitOnChangeTargets.includes(event.target) ) {
      this.handleSubmit();
    }
    Array.from(this.showOnChangeTargets).forEach(elm => elm.classList.remove('hidden'))
  }

  closeModal(id) {
    const checkbox = document.querySelector("#" + id);
    setTimeout(() => { checkbox.checked = false; });
  }
  
  addTemporaryAdditions(event) {
    let elements = [];

    // Ability to pass some values associated with the button pressed 
    // (Link <button name="name" value="value"> but still works with this.element.submit())
    if(event.params.name) {
      const elem = document.createElement("input");
      elem.setAttribute('type', 'hidden');
      elem.setAttribute('name', event.params.name);
      elem.setAttribute('value', event.params.value);
      this.element.append(elem);
      elements.push(elem);
    }

    if(event.params.additions) {
      for(const [key, value] of Object.entries(event.params.additions)) {
        const elem = document.createElement("input");
        elem.setAttribute('type', 'hidden');
        elem.setAttribute('name', key);
        elem.setAttribute('value', value);
        this.element.append(elem);
        elements.push(elem);
      }
    }

    return elements;
  }


  removeTemporaryAdditions(elements) {
    for(const elem of elements) {
      this.element.removeChild(elem);
    }
  }

  noTurboSubmit(event) {
    const additions = this.addTemporaryAdditions(event);
    this.element.submit();
    this.removeTemporaryAdditions(additions)
  }

  /*
   * Clear any elements marked 
   *
   * This is potentially dangerous since we it
   * will happen regardless of success or not in
   * the submission of forms.
   *
   */
  clearRequiredElements = () => {
    setTimeout(() => {
      this.clearOnSubmitTargets.forEach((elem) => {
        elem.value = "";
      });
    });
  }

  /*
   * Hook for native form submitting 
   */
  onSubmit = () => {
    this.clearRequiredElements()
  }

  /*
   * Call for any submitting that is outside native
   */
  handleSubmit = () => {
    this.element.requestSubmit()
    this.clearRequiredElements()
  }

  requestSubmit = (event) => {
    // Hack to get around turbo not respecting data-confirm messages on form.submit buttons
    if (event.params.confirm) {
      if(!window.confirm(event.params.confirmMessage)) {
        event.preventDefault()
        return
      }
    }

    if (event.params.formaction) {
      /* no-op if valid formaction will do it's thing */
      this.valid() ? 
        undefined :
        event.preventDefault()
    } else {
      event.preventDefault()

      if(Object.keys(event.params).includes("validate") && !event.params.validate) {
        this.element.setAttribute('novalidate','true')
        this.handleSubmit();
        this.element.removeAttribute('novalidate')
      } else if(this.valid()) {
        if(event.params.closeModal) this.closeModal(event.params.closeModal);

        // do not pass the submitter. Safari + turbostream has issues and will not submit the forms
        this.handleSubmit();
      }
    }
  }

  onDynamicRequiredFieldsTriggerChange = (event) => {
    if (event.target.checked) {
      this.dynamicRequiredFieldTargets.forEach(elm => elm.setAttribute('required', true))
    } else {
      this.dynamicRequiredFieldTargets.forEach(elm => elm.removeAttribute('required'))
    }
  }

  valid = () => {
    const standardElms = Array.from(this.element.querySelectorAll('input:not([type="hidden"]), textarea, select')).map(elm => elm.reportValidity())
    const stimulusElms = Array.from(this.element.querySelectorAll('[data-controller]')).map(elm => elm.validCheck ? elm.validCheck() : true)

    return standardElms.every(x => x == true) && stimulusElms.every(x => x == true)
  }

  get submitOnChange() {
    return this.element.dataset.submitOnChange;
  }

  get submitOnInput() {
    return this.element.dataset.submitOnInput;
  }

  disconnect() {
  }
}
