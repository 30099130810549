import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hideable"]
  static values = {
    cls: String
  }

  connect() {
    this.element.toggleClassController = this
  }

  toggle() {
    const t = this.hasHideableTarget ? this.hideableTarget : this.element

    if (this.clsValue) {
      this.clsValue.split(",").map(e => t.classList.toggle(e))
    } else {
      t.classList.toggle('opacity-60')
    }
  }
}
