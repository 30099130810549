import SaController from './sa_controller';
import { debounce } from 'lodash';

export default class extends SaController {
  connect() {
    this.debounceChanged = debounce(this.changed, 200);
    this.inputElement.addEventListener('input', this.debounceChanged)
  }

  changed = (event) => {
    const pathParts = this.path.split('?');
    let url = pathParts[0];
    let query = pathParts.slice(1).join('?');

    let params = new URLSearchParams(query);
    params.append('query', this.inputElement.value);

    this.element.setAttribute('src', url + "?" + params.toString());
  }

  get inputElement() {
    return document.getElementById(this.element.dataset.inputElement);
  }

  get path() {
    return this.element.dataset.basePath;
  }

  disconnect() {
    this.element.removeEventListener('change', this.changed);
  }
}
