import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ "pending", "scheduledSocial", "scheduledContent" ];

  connect() {
    this.fetch_pending();
    this.fetch_scheduled_social();
    this.fetch_scheduled_content();

    /* Turn off for now
    setInterval(() => {
      this.fetch_pending();
      this.fetch_scheduled_social();
      this.fetch_scheduled_content();
    }, this.timeBetweenUpdates)
    */
  }

  async fetch_pending() {
    this.pendingTarget.innerText = this.randomNumber
  }

  async fetch_scheduled_social() {
    this.scheduledSocialTarget.innerText = this.randomNumber
  }
  
  async fetch_scheduled_content() {
    this.scheduledContentTarget.innerText = this.randomNumber
  }

  get timeBetweenUpdates () {
    // return 30 * 60 * 1000;
    return 300 * 1000;
  }

  get randomNumber () {
    return Math.floor(Math.random() * (20 - 1 + 1)) + 1;
  }
}
