import SaController from './sa_controller';
import Highcharts from 'highcharts';


export default class extends SaController {
  connect() {
    console.log(this.chartData);
    //Highcharts.chart("chart", this.chartData);
    Highcharts.chart(this.element, this.chartData);
  }

  disconnect() {
  }

  get chartData() {
    return JSON.parse(this.element.querySelector("script").innerHTML)
  }
}

