import SectionController from './section_controller';
import '../../elements/progress_ring';

export default class extends SectionController {
  static targets = ['link']

  get state() {
    return {
      links: this.linkTargets.map(l => this.linkState(l))
    };
  }

  linkState(link) {
    const form = link.querySelector("form[data-controller=compose--form]")  
    if(form) {
      return this.formToObject(form);
    } else {
      return null;
    }
  }
}

