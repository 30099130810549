import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.addEventListener('click', this.confirmClick);
  }

  confirmClick = (event) => {
    if(this.inConfirm) {
      // Just do it
      this.inConfirm = false;
      document.removeEventListener('click', this.cancel);
    } else if(this.inline) {

      this.inConfirm = true; 
      this.previousText = this.element.textContent;
      this.element.textContent = this.message;

      document.addEventListener('click', this.cancel);

      event.preventDefault();
      event.stopPropagation();
    } else {
      const res = confirm(this.message)
      if(!res) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  cancel = (event) => {
    this.element.textContent = this.previousText;
    this.inConfirm = false;
    document.removeEventListener('click', this.cancel);
  }

  get message() {
    return this.element.dataset.message;
  }

  get inline() {
    return this.element.dataset.inline;
  }

  disconnect() {
    this.element.removeEventListener('click', this.confirmClick);
  }
}
