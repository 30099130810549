import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggable","togglable"]

  connect() {
    this.element.toggleVisibleController = this;
  }

  hide() {
    this.toggableTargets.forEach((elm) => {
      elm.classList.add("hidden");
    });
    this.togglableTargets.forEach((elm) => {
      elm.classList.add("hidden");
    });
  }

  show() {
    this.toggableTargets.forEach((elm) => {
      elm.classList.remove("hidden");
    });
    this.togglableTargets.forEach((elm) => {
      elm.classList.remove("hidden");
    });
  }

  toggle() {
    this.toggableTargets.forEach((elm) => {
      elm.classList.toggle("hidden");
    });
    this.togglableTargets.forEach((elm) => {
      elm.classList.toggle("hidden");
    });
  }

}


