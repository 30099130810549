import SaController from './sa_controller';
import { Editor } from '@tiptap/core'
import { Color } from '@tiptap/extension-color'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'

// Required by Color
import TextStyle from '@tiptap/extension-text-style'

export default class extends SaController {
  static targets = ["menu", "content"]
  static values = {
    placeholder: String,
    btnActive: String,
    required: Boolean,
    label: String,
    baseColor: String
  }

  connect() {
    this.editor = new Editor({
      element: this.element,
      onCreate: this.onEditorCreate,
      onUpdate: this.onEditorUpdate,
      onBlur: this.onEditorBlur,
      onSelectionUpdate: this.onEditorSelectionUpdated,
      extensions: [
        StarterKit,
        TextStyle,
        Color,
        Link.configure({
          openOnClick: true
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Placeholder.configure({
          placeholder: this.placeholderValue || "Enter rich text..."
        })
      ],
      content: this.contentTarget.value,
    })
  }

  disconnect() {
    if (this.label) {
      this.label.removeEventListener("click", (e) => this.editor.commands.focus())
    }

    if (this.baseColorValue) {
      const colorBtn = this.element.querySelector("[data-selected-color]")
      colorBtn.removeEventListener("click", (_) => this.element.querySelector("[data-btn-color]").focus())
    }
    
    this.editor.destroy()
  }

  updateState = (e) => {
    this.toggleParagraph()
    this.toggleHeadings()
    this.toggleLists()
    this.toggleAligns()
    this.toggleTextFormats()
    this.toggleCodeBlock()
    this.toggleLink()

    if (this.baseColorValue) {
      this.toggleColor()
    }
  }

  /*
    Sets required aria-* for content editable (and adds label click to focus)
  */
  onEditorCreate = (event) => {
    const editable = this.element.querySelector("div[contenteditable]")
    editable.setAttribute("role", "textbox")
    editable.setAttribute("aria-multiline", true)
    editable.setAttribute("aria-placeholder", this.placeholderValue || "Enter rich text...")

    if (this.labelValue) {
      this.label = document.querySelector("#" + this.labelValue)
      this.label.addEventListener("click", (e) => this.editor.commands.focus())
      editable.setAttribute("aria-labelledby", this.label.id)
    }

    if (this.requiredValue) {
      editable.setAttribute("aria-required", true)
    }

    if (this.baseColorValue) {
      this.previousColor = this.baseColorValue

      const colorBtn = this.element.querySelector("[data-selected-color]")
      colorBtn.addEventListener("click", (_) => this.element.querySelector("[data-btn-color]").focus())
    }
  }

  onEditorSelectionUpdated = (event) => {
    this.updateState(event)
  }

  onEditorBlur = (event) => {
    this.updateState(event)
  }

  onEditorUpdate = (e) => {
    this.contentTarget.value = e.editor.getHTML()
  }

  toggleActive(btn, force = undefined) {
    if (btn) {
      btn.classList.toggle("btn-active", force)
    }
  }

  clear = (e) => {
    e.preventDefault()
    this.editor.chain().focus().unsetAllMarks().run()
    this.editor.chain().focus().clearNodes().run()
  }

  bold = (e) => {
    e.preventDefault()
    this.toggleActive(e.target.closest("button"))
    this.editor.chain().focus().toggleBold().run()
  }

  italic = (e) => {
    e.preventDefault()
    this.toggleActive(e.target.closest("button"))
    this.editor.chain().focus().toggleItalic().run()
  }

  code = (e) => {
    e.preventDefault()
    this.toggleActive(e.target.closest("button"))
    this.editor.chain().focus().toggleCode().run()
  }

  strike = (e) => {
    e.preventDefault()
    this.toggleActive(e.target.closest("button"))
    this.editor.chain().focus().toggleStrike().run()
  }

  toggleTextFormats = () => {
    ["bold", "italic", "strike", "code"].forEach(a => {
      const btn = this.element.querySelector("[data-btn-" + a + "]")
      if (btn) {
        this.toggleActive(btn, this.editor.isActive(a))
      }
    })
  }

  bulletList = (e) => {
    e.preventDefault()
    this.toggleLists("bullet")
    this.editor.chain().focus().toggleBulletList().run()
  }

  orderedList = (e) => {
    e.preventDefault()
    this.toggleLists("ordered")
    this.editor.chain().focus().toggleOrderedList().run()
  }

  toggleLists = (list) => {
    ["bullet", "ordered"].forEach(a => {
      const btn = this.element.querySelector("[data-btn-" + a + "]")
      if (btn) {
        let force = undefined

        if (list) {
          force = list == a
        } else {
          force = this.editor.isActive(a + "List")
        }

        this.toggleActive(btn, force)
      }
    })
  }

  toggleParagraph = (btn) => {
    if (!btn) {
      btn = this.element.querySelector("[data-btn-p]")
    }

    this.toggleActive(btn, this.editor.isActive('paragraph'))
  }

  paragraph = (e) => {
    e.preventDefault()
    this.toggleParagraph(e.target.closest("button"))
    this.editor.chain().focus().setParagraph().run()
  }

  left = (e) => {
    e.preventDefault()
    this.toggleAligns("left")
    this.editor.chain().focus().setTextAlign('left').run()
  }

  center = (e) => {
    e.preventDefault()
    this.toggleAligns("center")
    this.editor.chain().focus().setTextAlign('center').run()
  }

  right = (e) => {
    e.preventDefault()
    this.toggleAligns("right")
    this.editor.chain().focus().setTextAlign('right').run()
  }

  justify = (e) => {
    e.preventDefault()
    this.toggleAligns("justify")
    this.editor.chain().focus().setTextAlign('justify').run()
  }

  toggleAligns = (align) => {
    ["left", "center", "right", "justify"].forEach(a => {
      const btn = this.element.querySelector("[data-btn-" + a + "]")
      if (btn) {
        let force = undefined

        if (align) {
          force = align == a
        } else {
          force = this.editor.isActive({ textAlign: a })
        }

        this.toggleActive(btn, force)
      }
    })
  }

  h1 = (e) => {
    e.preventDefault()
    this.toggleHeadings(1)
    this.editor.chain().focus().toggleHeading({ level: 1 }).run()
  }

  h2 = (e) => {
    e.preventDefault()
    this.toggleHeadings(2)
    this.editor.chain().focus().toggleHeading({ level: 2 }).run()
  }

  h3 = (e) => {
    e.preventDefault()
    this.toggleHeadings(3)
    this.editor.chain().focus().toggleHeading({ level: 3 }).run()
  }

  h4 = (e) => {
    e.preventDefault()
    this.toggleHeadings(4)
    this.editor.chain().focus().toggleHeading({ level: 4 }).run()
  }

  h5 = (e) => {
    e.preventDefault()
    this.toggleHeadings(5)
    this.editor.chain().focus().toggleHeading({ level: 5 }).run()
  }

  h6 = (e) => {
    e.preventDefault()
    this.toggleHeadings(6)
    this.editor.chain().focus().toggleHeading({ level: 6 }).run()
  }

  toggleHeadings = (level) => {
    [1, 2, 3, 4].forEach(num => {
      const btn = this.element.querySelector("[data-btn-h" + num + "]")
      if (btn) {
        let force = undefined

        if (level) {
          force = level == num
        } else {
          force = this.editor.isActive('heading', { level: num })
        }

        this.toggleActive(btn, force)
      }
    })
  }

  toggleCodeBlock = (btn) => {
    if (!btn) {
      btn = this.element.querySelector("[data-btn-codeBlock]")
    }

    this.toggleActive(btn, this.editor.isActive('codeBlock'))
  }

  codeBlock = (e) => {
    e.preventDefault()
    this.toggleActive(e.target.closest("button"))
    this.editor.chain().focus().toggleCodeBlock().run()
  }

  undo = (e) => {
    e.preventDefault()
    this.editor.chain().focus().undo().run()
  }

  redo = (e) => {
    e.preventDefault()
    this.editor.chain().focus().redo().run()
  }

  horizontal_rule = (e) => {
    e.preventDefault()
    this.editor.chain().focus().setHorizontalRule().run()
  }

  quote = (e) => {
    e.preventDefault()
    this.toggleActive(e.target.closest("button"))
    this.editor.chain().focus().toggleBlockquote().run()
  }

  hardBreak = (e) => {
    e.preventDefault()
    this.editor.chain().focus().setHardBreak().run()
  }

  toggleColor = (target) => {
    const colorBtn = this.element.querySelector("[data-selected-color]")

    if (!target) {
      let color = this.editor.getAttributes('textStyle').color

      // if we get an rbg(xxx, xxx, xxx) see if we can parse it
      if (color && color.substr(0, 3) === "rgb") {
        color = color.slice(color.indexOf("(") + 1, color.indexOf(")")).split(", ").map(x => parseInt(x));
        color = this.rgbToHex(...color)
      }

      // see if we have the color of pasted/current text
      target = this.element.querySelector("[data-color-value=\"" + color + "\"]")

      // default (black)
      if (!target) {
        target = this.element.querySelector("[data-color-value=\"#000\"]")
      }
    }

    target.closest("[data-color-list]")
      .querySelectorAll("[aria-selected]")
      .forEach(ele => ele.setAttribute("aria-selected", false))

    const option = target.closest("[data-color-option]")
    option.setAttribute("aria-selected", true)

    colorBtn.classList.remove(this.previousColor)
    colorBtn.classList.add(target.dataset.colorBg)

    this.previousColor = target.dataset.colorBg
  }

  color = (e) => {
    e.preventDefault()
    const target = e.target.closest("[data-action]")

    this.toggleColor(target)
    this.editor.chain().focus().setColor(target.dataset.colorValue).run()
  }

  toggleLink = () => {
    if (this.editor.isActive('link')) {
      const unset = this.element.querySelector("button[data-btn-unsetlink]")
      if (unset) {
        unset.removeAttribute("disabled")
      }
    }
  }

  unsetLink = (e) => {
    e.preventDefault()
    this.editor.chain().focus().unsetLink().run()
    e.target.closest("button").setAttribute("disabled", true)
  }

  setLink = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const previousUrl = this.editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    const valid = /^https?:\/\//.test(url)

    // empty
    if (!valid || url === '') {
      if (!valid) {
        window.alert("The given url: [" + url + "] was invalid. Please supply a valid URL. Examples: [https://www.google.com, http://www.google.com]")
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run()

      return
    }

    // update link
    this.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
  }

  /* Utility function for toggleColor */
  rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
}
