import ComposeController from '../compose_controller';
import '../../elements/progress_ring';

export default class extends ComposeController {
  static targets = ['progress', 'image', 'video', 'assetId', 'file', 'thumbnail', 'thumbnailField', 'thumbnailWarning', 'altTextWarning'];

  static values = {
    id: String,
    url: String,
    originalUrl: String,
    name: String
  }

  connect() {
    this.composeManager.element.addEventListener('upload_progress', this.onProgress);
    this.composeManager.element.addEventListener('upload_success', this.onSuccess);
  }

  disconnect() {
    this.composeManager.element.removeEventListener('upload_progress', this.onProgress);
    this.composeManager.element.removeEventListener('upload_success', this.onSuccess);
  }

  setPreview(file) {
    const url = URL.createObjectURL(file);
    if(this.hasImageTarget) {
      this.imageTarget.src = url;
    }

    if(this.hasVideoTarget) {
      this.videoTarget.src = url;
    }
  }

  videoSnapshot() {
    const canvas = document.createElement('canvas');
    const video = this.videoTarget;
    if (!video.videoWidth && !video.videoHeight) {
      throw new Error("Error snapshotting video");
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL();
    this.thumbnailTarget.src = dataURL;
    this.thumbnailWarningTarget.classList.remove("hidden");
    this.srcToFile(dataURL, 'thumb.png', 'image/png').then((file) => {
      this.composeManager.requestAsset(file, asset => { 
        this.thumbnailFieldTarget.value = asset.id;
        // TODO - Should we reload previews on all successful uploads?
        this.composeManager.onAssetUpload(asset, () => {
          this.composeManager.reloadPreview() 
        });

        this.takeAction(
          'media', 
          'attach_thumbnail', 
          {asset_id: this.idValue, thumbnail_asset_id: asset.id});
      })
    });
  }

  get uploading() {
    return !this.progressTarget.classList.contains('hidden');
  }

  srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], fileName, {type:mimeType});})
    );
  }

  onSuccess = (event) => {
    if(this.idValue === event.detail.asset.id) {
      // TODO
    }
  }

  onProgress = (event) => {
    if(this.idValue === event.detail.asset.id) {
      this.setProgress(event.detail.progress);
    }
  }

  showAltTextWarning() {
    this.altTextWarningTarget.classList.remove("hidden");
  }

  setProgress(value) {
    this.progressTarget.classList.remove('hidden');
    this.progressTarget.setAttribute('progress', value*100);
    if(value == 1) {
      this.progressTarget.classList.add('hidden');
    } 
  }

  modify() {
    if(this.uploading) { 
      alert("Please wait for the image to finish uploading") 
    } else {
      this.composeManager.editImage(this.idValue, this.nameValue, this.urlValue).then(asset => {
        this.composeManager.updateReveal(`/composable/${this.composeManager.idValue}/asset/detail?asset_id=${asset.id}&replace_asset_id=${this.idValue}`);
      });
    }
  }

  replace = (event) => {
    this.fileTarget.click();
  }

  fixIt(event) {
    if(this.uploading) { 
      alert("Please wait for the image to finish uploading") 
      return
    }

    if(event.params.constraint == 'image_size') {
      this.composeManager.autoConvertAsset(this.idValue);
      this.composeManager.hideReveal();
    } else if(event.params.constraint == 'format' || event.params.constraint == 'video_format') {
      this.composeManager.autoConvertAsset(this.idValue);
      this.composeManager.hideReveal();
    } else if(event.params.constraint == 'video_length') {
      // TODO
    } else if(event.params.constraint == 'video_width') {
      this.composeManager.autoConvertAsset(this.idValue);
      this.composeManager.hideReveal();
    } else if(event.params.constraint == 'image_aspect_ratio') {
      this.composeManager.editImage(this.idValue, this.nameValue, this.urlValue, event.params.network).then(asset => {
        this.composeManager.updateReveal(`/composable/${this.composeManager.idValue}/asset/detail?asset_id=${asset.id}&replace_asset_id=${this.idValue}`);
      });
    }
  }

  createAssets = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      this.composeManager.requestAsset(file, asset => {
        this.composeManager.updateReveal(`/composable/${this.composeManager.idValue}/asset/detail?asset_id=${asset.id}&replace_asset_id=${this.idValue}`);
      });
    })
  }
}
