import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ 'calendar', 'dateInput', 'value' ]
  static values = { date: String }

  connect() {
    this.dateInputTarget.addEventListener('keydown', this.dateInputHandler);
  }

  toggleCalendar() {
    if (this.calendarTarget.style.display === 'block') {
      this.calendarTarget.style.display = 'none';
    } else {
      this.calendarTarget.style.display = 'block';
    }
  }

  inputChanged() {
    if(this.dateInputTarget.value.length === 0 || this.dateInputTarget.value.length === 8) {
      let date = new Date(this.dateInputTarget.value);
      this.setValue(date);
    } else {
      return;
    }
  }

  dateValueChanged() {
    this.valueTarget.value = this.dateValue;
  }

  setValue(date) {
    this.dateValue = date; 
  }

  dateInputHandler() {
    this.value = this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'');
  }

  toggleDate(event) {
    let date = new Date(event.params.date);
    if (this.dateValue === JSON.stringify(date.toJSON())) {
       this.setValue('');
    } else {
       this.setValue(date);
    }
  }
}
