import SaController from './sa_controller';

export default class extends SaController {

  connect() {
    this.element.addEventListener('click', this.clicked);
  }

  clicked = (event) => {
    this.modalCheckbox.checked = true;
  }

  get modalCheckbox() {
    return document.getElementById(this.element.dataset.modal);
  }

  disconnect() {
    this.element.removeEventListener('click', this.clicked);
  }
}

