import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    //this.element.addEventListener('pjax:error', this.showNavigationError.bind(this));
  }

  showNavigationError(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.emit("toast:show", 
      { 
        type: "error", 
        icon: "face-yikes",
        text: "Something went wrong loading the page." 
      }, 
      window
    );
  }
}

