import SaController from '../sa_controller';

export default class extends SaController {
  connect() {
    if (!this.element.classList.contains("controller-attached")) {
      this.element.classList.add("controller-attached");
      this.element.addEventListener('click', this.showNotification.bind(this));
    }
  }

  showNotification() {
    this.emit("toast:show", { 
        type: "success", 
        icon: "face-happy",
        header: "Success!",
        message: "Copied link to clipboard" 
      }, 
      window
    );
  }
}

