import SaController from "./sa_controller";

export default class extends SaController {
  static values = { id: String };
  
  dismiss() {
    this.post("/actions/dismiss_notification", { id: this.idValue });
    this.element.remove();
  }
}
