import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["front", "back", "inner"]

  connect() {
    this.setupCSS()
  }

  setupCSS() {
    this.element.style.perspective = "1000px";

    this.innerTarget.style.position = 'relative';
    this.innerTarget.style.transformStyle = 'preserve-3d';
    this.innerTarget.style.width = "100%";
    this.innerTarget.style.height = "100%";
    this.innerTarget.style.transitionDuration = "200ms"

    this.frontTarget.style.backfaceVisibility = 'hidden';
    this.frontTarget.style.position = 'absolute';
    this.frontTarget.style.width = '100%';
    this.frontTarget.style.height = '100%';

    this.backTarget.style.backfaceVisibility = 'hidden';
    this.backTarget.style.position = 'absolute';
    this.backTarget.style.width = '100%';
    this.backTarget.style.height = '100%';
    this.backTarget.style.transform = 'rotateY(180deg)';
  }

  flip() {
    if(this.innerTarget.style.transform) {
      this.element.classList.remove('flipped');
      this.innerTarget.style.transform = "";
    } else {
      this.element.classList.add('flipped');
      this.innerTarget.style.transform = "rotateY(180deg)";
    }
  }

}

