import SaController from './sa_controller';

export default class extends SaController {
  static targets = ["container"]

  get hideOnClear() {
    return this.element.hasAttribute("data-hide-on-clear")
  }

  get containerTarget() {
    return document.querySelector(this.element.dataset.container)
  }

  get frameToClear() {
    return document.querySelector(this.element.dataset.frame) || this.element.querySelector("turbo-frame")
  }

  connect() {
    this.element.addEventListener("click", this.onElementClick)
    this.containerTarget?.classList?.toggle("hidden", false)

    this.element.clearTurboFrameController = this
  }

  disconnect() {
  }

  click = () => {
    this.element.click()
  }

  onElementClick = (evt) => {
    if (this.frameToClear) {
      this.frameToClear.innerHTML = ""

      if (this.hideOnClear) {
        this.containerTarget.classList.toggle("hidden", true)
      }
    }
  }
}
