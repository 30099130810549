import { Controller } from '@hotwired/stimulus';
import {computePosition, autoPlacement, shift, offset, arrow} from '@floating-ui/dom';

export default class extends Controller {
  static targets = ["trigger", "tip", "arrow"]

  connect() {
    this.trigger = this.getTrigger();
    this.tip = this.getTip();
    this.arrowEl = this.getArrow();
    this.applyListeners();
  }

  update(trigger, tip, arrowEl) {
    computePosition(trigger, tip, {
      middleware: [
        offset(6),
        shift({padding: 6}),
        autoPlacement(),
        arrow({element: arrowEl})
      ]
    }).then(({x, y, placement, middlewareData}) => {
      Object.assign(tip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    
      if (middlewareData.arrow) {
        const {x, y} = middlewareData.arrow;
        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]];
      
        Object.assign(arrowEl.style, {
          left: x != null ? `${x}px` : '',
          top: y != null ? `${y}px` : '',
          right: '',
          bottom: '',
          transform: this.arrow_rotate(placement),
          [staticSide]: this.arrow_offset(placement),
        });
      }
    })
  }

  arrow_rotate(placement) {
    switch (placement) {
      case 'left':
      case 'left-start':
      case 'left-end':
        return 'rotate(180deg)';
      case 'top':
      case 'top-start':
      case 'top-end':
        return 'rotate(270deg)';
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return 'rotate(90deg)';
      default: // right is default
        return '';
    }
  }

  arrow_offset(placement) {
    switch (placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return '-7px';
      default: // left/right is default
        return '-5px';
    }
  }

  show() {
    this.tip.classList.remove("hidden");
    this.update(this.trigger, this.tip, this.arrowEl);
  }

  hide() {
    this.tip.classList.add("hidden");
  }

  getTrigger() {
    if (this.hasTriggerTarget) {
      return this.triggerTarget;
    } else {
      return this.element;
    }
  }

  getTip() {
    if (this.hasTipTarget) {
      return this.tipTarget;
    } else {
      return this.element;
    }
  }

  getArrow() {
    if (this.hasArrowTarget) {
      return this.arrowTarget;
    } else {
      return this.element;
    }
  }

  applyListeners() {[
    ['mouseenter', this.show.bind(this)],
    ['mouseleave', this.hide.bind(this)],
    ['focus', this.show.bind(this)],
    ['blur', this.hide.bind(this)]]
    .forEach(([e, listener]) => {this.trigger.addEventListener(e, listener);}
  )}
}
