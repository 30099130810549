import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    currentlyOptimized: Boolean
  }

  static targets = ["icon", "input", "link", "inputGroup", "dummyGroup", "dummyInput", "buttonTooltip", "time", "shade", "tooltip"];
  static outlets = ["preset-time-picker-component"];

  connect() {
    this.presetTimePickerComponentOutlet.addTimeChangeCallback(() => this.timeChanged());
    this.presetTimePickerComponentOutlet.addDateChangeCallback(() => this.dateChanged());
    this.currentlyOptimizedValueChanged();
  }

  currentlyOptimizedValueChanged() {
    if(this.disabled) {
      this.setDisabled();
    } else {
      this.currentlyOptimizedValue ? this.setOptimizeStyle() : this.clearOptimizeStyle();
    }
  }

  timeChanged() {
    this.currentlyOptimizedValue = false;
    this.inputTarget.value = false;
  }

  dateChanged() {
    if (this.disabled) {
      this.currentlyOptimizedValue = false;
      this.inputTarget.value = false;
      return;
    }
    this.currentlyOptimizedValue = true;
    this.linkTarget.click();
  }

  updatePath() {
    const date = this.dateElement.value;
    this.linkTarget.setAttribute('href', this.path + '?date=' + date.toString());
  }

  clearOptimizeStyle() {
    this.linkTarget.classList.remove("btn-disabled");
    this.linkTarget.classList.add("btn-outline", "border-base-300");
    this.linkTarget.classList.remove("btn-primary");
    this.tooltipTarget.setAttribute('data-tip', 'Select Auto-Schedule'); 
  }

  setOptimizeStyle() {
    this.linkTarget.classList.remove("btn-disabled");
    this.linkTarget.classList.remove("btn-outline", "border-base-300");
    this.linkTarget.classList.add("btn-primary");
    this.tooltipTarget.setAttribute('data-tip', 'Select Auto-Schedule'); 
  }

  setDisabled() {
    this.linkTarget.setAttribute('disabled', true)
    this.linkTarget.classList.add("btn-disabled");
    this.tooltipTarget.setAttribute('data-tip', 'Select Future Date'); 
  }
  
  get dateElement() {
    return document.querySelector('#compose_date');
  }

  get path() {
    return this.linkTarget.getAttribute('href').split('?')[0]; // get just the path and ignore the query params
  }

  showOptimizing() {
    this.linkTarget.classList.add("btn-primary");
    this.linkTarget.classList.remove("btn-outline", "border-base-300");
    this.shadeTarget.classList.add("translate-x-0", "w-32", "transition", "ease-in-out", "transition-all", "duration-250", "h-8");
    this.shadeTarget.classList.remove("translate-x-32");
  }

  stopOptimizing() {
    this.shadeTarget.classList.add("translate-x-32");
    this.shadeTarget.classList.remove("translate-x-0");
    setTimeout(() => {
      this.shadeTarget.classList.remove("w-32");
    }, 250);
  }

  setOptimize() {
    if (this.disabled) {
      return;
    }

    this.showOptimizing();

    /* Slight delay to allow for animation */
    setTimeout(() => {
      this.inputTarget.value = true;
      this.currentlyOptimizedValue = true;
      this.updatePath();
      get(this.linkTarget.href, {responseKind: "turbo-stream"}).then(() => this.stopOptimizing());
    }, 600);

  }

  get disabled() {
      const currentTime = Date.now();
      const today = new Date().setHours(0, 0, 0, 0);
      const startTime = new Date().setHours(18, 59, 59, 0);
      const endTime = new Date().setHours(23, 59, 59, 0);
      const inputDay = new Date(this.presetTimePickerComponentOutlet.datetimeTarget.value).setHours(0, 0, 0, 0);

      return (currentTime >= startTime && currentTime <= endTime && inputDay == today) || inputDay < today;
  }
}
