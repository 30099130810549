import ComposeController from '../compose_controller';
import Sortable from '../sortable_controller'

export default class extends Sortable {
  connect() {
    super.connect()
  }

  onUpdate({item, newIndex}) {
    const controller = item.assetController;
    controller.takeAction('media', 'update_asset', {asset_id: controller.id, position: newIndex + 1});
  }


  get options() {
    return {
      animation: 150,
      handle: undefined,
      filter: ".add-media",
      preventOnFilter: false,
      onMove: function(evt) {
        return evt.related.className.indexOf('add-media') === -1;
      },
      onUpdate: this.onUpdate
    }
  }
}
