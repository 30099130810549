import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.attached = true;
    this.element.addEventListener('click', this.openCompose);
  }

  disconnect() {
    this.element.removeEventListener('click', this.openCompose)
    window.removeEventListener("close_compose", this.handleClose);
  }

  handleClose = (event) => {
    window.removeEventListener("close_compose", this.handleClose); 

    const modal = document.querySelector("#compose-modal")
    modal.open = false;

    if(event.detail && event.detail.updated) {
      this.frameToReload?.reload();
    }

    if (this.requestOnClose) {
      this.get(this.requestOnClose)
          .then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html))
    }
  }

  get frameToReload() {
    return document.querySelector("#content-activities");
  }

  get requestOnClose() {
    return this.element.dataset.requestOnClose
  }
  
  get targetId() {
    return this.element.dataset.targetId;
  }
  
  get postId() {
    return this.element.dataset.postId;
  }

  get postType() {
    return this.element.dataset.type || 'post';
  }

  get taskId() {
    return this.element.dataset.taskId;
  }

  get mode() {
    return this.element.dataset.mode || 'edit';
  }

  get clone() {
    // Composable ID to clone
    return this.element.dataset.clone || false;
  }

  get ancestorScheduledAt() {
    const elem = this.element.closest("[data-open-compose-scheduled-at]");
    if(elem) {
      return elem.dataset.openComposeScheduledAt;
    } else {
      return null;
    }
  }

  get scheduledAt() {
    if(this.element.dataset.scheduledAt) {
      return this.element.dataset.scheduledAt;
    } else if(this.ancestorScheduledAt) {
      // This is a special case to avoid having a different cache
      // for all scheduled_at
      return this.ancestorScheduledAt;
    }
  }

  get preapprovedResponseId() {
    return this.element.dataset.preapprovedResponseId;
  }

  get preapprovedMessage() {
    return this.element.dataset.preapprovedMessage;
  }

  get style() {
    return this.element.dataset.style;
  }

  get contentLibraryItemId() {
    return this.element.dataset.contentLibraryItemId;
  }

  get targetExternalId() {
    return this.element.dataset.targetExternalId;
  }

  get targetChannelId() {
    return this.element.dataset.targetChannelId;
  }
  
  get targetFeedId() {
    return this.element.dataset.targetFeedId;
  }

  async storeAsSocialContent() {
    const resp = await this.post("/social/feed/store_external", {external_id: this.targetExternalId, feed_id: this.targetFeedId});
    const data = await resp.json();
    this.element.dataset.targetId = data.id;
  }

  get composeUrl() {
    const url = new URL(`${location.origin}/channel-activities/${this.postId}/begin-compose`);
    const params = url.searchParams;
    
    params.set('style', this.postType);
    params.set('mode', this.mode);
    params.set('type', this.postType);
    if(this.targetId)              { params.set('targetId', this.targetId) }
    if(this.scheduledAt)           { params.set('scheduledAt', this.scheduledAt) }
    if(this.taskId)                { params.set('taskId', this.taskId) }
    if(this.preapprovedResponseId) { params.set('preapprovedResponseId', this.preapprovedResponseId) }
    if(this.preapprovedMessage)    { params.set('preapprovedMessage', this.preapprovedMessage) }
    if(this.contentLibraryItemId)  { params.set('contentLibraryItemId', this.contentLibraryItemId) }
    if(this.clone)                 { params.set('clone', this.clone) }

    url.search = params.toString();
    return url.toString();
  }

  openCompose = async(event) => {
    if(this.targetExternalId) {
      await this.storeAsSocialContent();
    }

    event.preventDefault();
    event.stopPropagation();
    document.querySelector("#new-compose-modal").checked = true
    document.querySelector("#compose-frame").src = this.composeUrl;
  }
}
