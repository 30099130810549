import { Controller } from "@hotwired/stimulus"
import {computePosition, autoPlacement, inline, autoUpdate} from '@floating-ui/dom';

export default class extends Controller {
  static targets = ['input', 'list', 'selected', 'remoteFrame', 'popup', 'value', 'anchor', 'selectedText', 'active'];
  static values = {
    url: String, 
    opened: Boolean,
    text: String,
    textBased: Boolean
  };

  initialize() {
    this.openedValueChanged();
  }

  connect() {
    console.log('Connected');
    this.check();
  }

  disconnect() {
  }

  focus() {
    this.openedValue = true;
  }

  openedValueChanged(value) {
    console.log('Opened Value Changed', value);
    if (this.openedValue) {
      this.popupTarget.style.display = 'block';
      this.place()
      this.fetchData();
    } else {
      this.popupTarget.style.display = 'none';
    }
  }

  place() {
    computePosition(this.anchorTarget, this.popupTarget, {
      placement: 'bottom-start'
    }).then(({x, y}) => {
      Object.assign(this.popupTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  filter() {
    this.fetchData();
  }

  check = () => {
    if(this.valueTarget.value.length > 0) {
      this.selectedTarget.style.display = 'flex';
      this.activeTarget.style.display = 'none';
    } else {
      this.activeTarget.style.display = 'flex';
      this.selectedTarget.style.display = 'none';
    }
  }

  select(event) {
    this.valueTarget.value = event.params.value || event.currentTarget.dataset.value;
    if(this.textBasedValue) {
      this.textValue = event.params.text || event.target.innerText;
    } else {
      this.selectedTextTarget.innerHTML = event.currentTarget.innerHTML;
    }
    this.check();
    this.openedValue = false;
    document.activeElement.blur();
  }

  fetchData = () => {
    this.remoteFrameTarget.src = this.urlValue + `?search=${this.inputTarget.value}&exclude=${this.valueTarget.value.split(',')}`;
  }

  clear() {
    console.log("CLEAR");
    this.inputTarget.value = '';
    this.valueTarget.value = '';
    this.check();
    this.inputTarget.focus();
  }
}
