import SaController from './sa_controller';

export default class extends SaController {
  static classes = ['on'];

  connect() {
    this.checkbox.addEventListener('change', this.checkboxChanged);
  }

  checkboxChanged = () => {
    if(this.on) {
      this.element.classList.add(this.onClass);
    }
    if(this.off) {
      this.element.classList.remove(this.onClass);
    }
  }

  get on() {
    return this.checkbox.checked;
  }

  get off() {
    return !this.checkbox.checked;
  }

  get checkbox() {
    return document.getElementById(this.checkboxId);
  }

  get checkboxId() {
    return this.element.dataset.checkboxId;
  }

}


