import TextareaAutogrow from 'stimulus-textarea-autogrow'

export default class extends TextareaAutogrow {
  connect() {
    super.connect()
    // We hide the textarea with notes initially. We need to re-run when shown
    this.element.addEventListener('focus', () => { super.connect(); });
  }
}

