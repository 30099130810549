import SaController from '../sa_controller';

export default class extends SaController {
  static targets = ["item"]

  connect() {
    if (this.hasItemTarget) {
      this.itemTargets.forEach(x => x.addEventListener("click", this.clearSelected))
    }
  }

  disconnect() {
    if (this.hasItemTarget) {
      this.itemTargets.forEach(x => x.removeEventListener("click", this.clearSelected))
    }
  }

  clearSelected = (e) => {
    this.itemTargets
      .filter(li => ["true", true].includes(li.ariaSelected))
      .forEach(x => x.setAttribute('aria-selected', false))

    e.target.closest("li").ariaSelected = "true"
  }
}
