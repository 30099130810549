import SaController from './sa_controller';

/*
 * Generic Controller for running script on turbo frame load
 *
 */
export default class extends SaController {
  connect() {
    const func = new Function('element', this.element.innerHTML)
    func(this.element);
  }
}

