import flatpickr from "./flatpickr_controller.js";

import "flatpickr/dist/themes/dark.css";

import { english } from "flatpickr/dist/l10n/default.js";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends flatpickr {
  connect() {
    // Set validation hook
    if(this.element.nodeName === "INPUT" && this.element.hasAttribute('required')) {
      this.setupValidation();
    }

    super.connect();
  }

  inputBlur(event) {
    if (event.target.required) {
      if (event.target.value === "") {
        event.target.setAttribute('error', true);
      } else {
        event.target.removeAttribute('error');
      }
    }
  }

  setupValidation() {
    if(this.form) {
      this.form.addEventListener('submit', this.validate.bind(this))
    }

    this.element.addEventListener('blur', this.inputBlur.bind(this));
    this.element.validCheck = this.validate.bind(this);
  }

  validate(focusOnError) {
    if(this.element.value === undefined || this.element.value === "") {
      this.element.setAttribute('error', true);

      return false;
    } else {
      this.element.removeAttribute('error');
      return true;
    }
  }
}
