import SaController from './sa_controller';

export default class extends SaController {

  connect() {
    this.drawerCheckbox.addEventListener('change', this.editDrawerChanged);
  }

  editDrawerChanged = () => {
    if(!this.drawerCheckbox.checked) {
      //Clear out active rows
      Array.from(document.querySelectorAll("tr")).forEach(elem => elem.classList.remove('active'));
    } 

    this.showLoading()
  }

  showLoading = () => {
    this.element.querySelector("#edit-drawer-frame").innerHTML = "Loading..."
  }

  get drawerCheckbox() {
    return this.element.querySelector("#edit-drawer")
  }

  disconnect() {
    this.drawerCheckbox.removeEventListener('change', this.editDrawerChanged);
  }
}
