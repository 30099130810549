import SaController from './sa_controller';

export default class extends SaController {
  static targets = ['output','handle'];

  connect() {
    console.log('DebugComponentController connected');
    this.element.addEventListener('mousedown', this.mouseDown);
    const position = JSON.parse(window.localStorage.getItem('debugComponentPosition'));
    if (position) {
      this.element.style.top = position.top;
      this.element.style.left = position.left;
    }
  }

  mouseDown = (event) => {
    event.preventDefault();
    this.pos3 = event.clientX;
    this.pos4 = event.clientY;
    this.element.addEventListener('mousemove', this.mouseMove);
    this.element.addEventListener('mouseup', this.mouseUp);
  }

  mouseMove = (event) => {
    event.preventDefault();
    this.pos1 = this.pos3 - event.clientX;
    this.pos2 = this.pos4 - event.clientY;
    this.pos3 = event.clientX;
    this.pos4 = event.clientY;
    this.element.style.top = (this.element.offsetTop - this.pos2) + 'px';
    this.element.style.left = (this.element.offsetLeft - this.pos1) + 'px';
  }

  mouseUp = (event) => {
    event.preventDefault();
    this.element.removeEventListener('mousemove', this.mouseMove);
    this.element.removeEventListener('mouseup', this.mouseUp);
    window.localStorage.setItem('debugComponentPosition', JSON.stringify({top: this.element.style.top, left: this.element.style.left}));
  }

  disconnect() {
    console.log('DebugComponentController disconnected');
  }

  output() {
  }
}
