import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['on','off','focusOn'];
  static values = {
    on: Boolean
  }

  connect() {
   // this.onTarget.classList.add('hidden')
    this.element.inputSwapController = this;
    this.update();
  }

  update() {
    if(this.onValue) {
      this.onTargets.forEach(elem => elem.classList.remove('hidden'));
      this.offTargets.forEach(elem => elem.classList.add('hidden'));

      setTimeout(() => {
        this.focusOnTarget.focus();
        this.focusOnTarget.click();
      });

    } else {
      this.onTargets.forEach(elem => elem.classList.add('hidden'));
      this.offTargets.forEach(elem => elem.classList.remove('hidden'));
    }
  }

  onValueChanged() {
    this.update()
  }

  toggle() {
    this.onValue = !this.onValue;
  }


  /*
  get on() {
    return this.offTarget.classList.contains('hidden');
  }


  update() {
    this.onTargets.forEach(elem => elem.classList.toggle('hidden'));
    this.offTargets.forEach(elem => elem.classList.toggle('hidden'));

    setTimeout(() => {
      if(this.on && this.focusOnValue) {
        this.element.querySelector(this.focusOnValue).focus();
        this.element.querySelector(this.focusOnValue).click();
      } 
      
      if(this.on && this.hasFocusOnTarget) {
        this.focusOnTarget.focus();
        this.focusOnTarget.click();
      } 

      if(!this.on && this.focusOffValue) {
        this.element.querySelector(this.focusOffValue).focus();
        this.element.querySelector(this.focusOffValue).click();
      } 

      if(!this.on && this.hasFocusOffTarget) {
        this.focusOffTarget.focus();
        this.focusOffTarget.click();
      } 
    });
  }
  */
}

