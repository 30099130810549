import SaController from './sa_controller';

export default class extends SaController {
  static values = {for: String};

  connect() {
    this.element.addEventListener('click', this.openModal.bind(this));
  }

  openModal() {
    // Close current modals
    this.emit('close-modal', {});
    setTimeout(() => {
      this.emit('open-modal', {for: this.element.attributes.for.value});
    });
  }
}

