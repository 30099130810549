import SaController from './sa_controller';
import TomSelect from 'tom-select';

import 'tom-select/dist/css/tom-select';

export default class extends SaController {
  static values = {
    options: Object
  }

  connect() {
    this.tomSelect = new TomSelect(this.element, this.selectOptions);
  }

  get pluginOptions() {
    let plugins = []

    if (this.optionsValue.plugins) {
      plugins = plugins.concat(this.optionsValue.plugins)
      return plugins
    } else {
      return { remove_button: { title: 'Remove this item' } }
    }
  }

  get defaultOptions() {
    return { 
      plugins: this.pluginOptions,
      create: true,
      maxItems: null,
      closeOnSelect: true,
      placeholder: 'Enter a value',
      render: {
        no_results: () => { return '<div/>' }
      }
    }
  }

  get selectOptions() {
    return Object.assign(
      this.defaultOptions,
      this.optionsValue
    )
  }
}

