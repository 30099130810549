import SaController from "../sa_controller";

export default class extends SaController {
  static targets = ['title', 'locationInput'];

  connect() {
    this.onLoadPreview();
  }

  //Sets initial state for display of Title field
  onLoadPreview() {
    if (this.locationInputTarget.value === "help_and_resources") {
      this.titleTarget.style.display = "block";
    } else {
      this.titleTarget.style.display = "none";
    }
  }

  //Updates state for display of Title field
  locationChange() {
    if (this.locationInputTarget.value === "help_and_resources") {
      this.titleTarget.style.display = "block";
    } else {
      this.titleTarget.style.display = "none";
    }
  }
}
