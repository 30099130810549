import SaController from './sa_controller';
import Base64 from 'base-64';

export default class extends SaController {
  ImgProxyUrl = "https://imgproxy.socialassurance.com/img";

  startProcessing() {
    this.element.setAttribute('processing', true);
    if(window.show_processing) {
      this.element.classList.add('ring');
    }
  }

  endProcessing() {
    this.element.removeAttribute('processing');
    if(window.show_processing) {
      this.element.classList.remove('ring');
    }
  }

  startLoading() {
    this.element.classList.add('animate-pulse')
  }
  
  hideLoading() {
    this.element.classList.remove('animate-pulse')
  }
  

  get composeManager() {
    return this.element.closest("[data-controller='compose--manager']")?.composeManager;
  }

  takeActionEvent = (event) => {
    const params = event.params;
    if(params.pause) {
      this.takeActionPause(params.key, params.action, params.params || {});
    } else {
      this.takeAction(params.key, params.action, params.params || {});
    }
  }

  takeActionPause = async (key, action, params) => {
    this.startProcessing();
    await this.composeManager.sideSubmitPause(`/composable/${this.composeManager.idValue}`, Object.assign(params, {take_action: action, key: key}))
    this.endProcessing();
  }

  takeAction = async (key, action, params) => {
    this.startProcessing();
    await this.composeManager.sideSubmit(`/composable/${this.composeManager.idValue}`, Object.assign(params, {take_action: action, key: key}))
    this.endProcessing();
  }

  imgProxy = (url, width) => {
    if(url.startsWith('data')) {
      // We ain't proxying no dataUrls
      return url;
    }
    const encodedUrl = Base64.encode(url);

    if(width) {
      return `${window.IMG_PROXY}/img/${width}x/?b64_url=${encodedUrl}`;
    } else {
      return `${window.IMG_PROXY}/img/?b64_url=${encodedUrl}`;
    }
  }
}
