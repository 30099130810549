import SaController from "./sa_controller";

export default class extends SaController {
  static targets = ["tab", "content"]
  
  get activeTab () {
    return this.tabTargets.filter(t => t.classList.contains("tab-active"))
  }

  connect() {
    this.tabTargets.forEach(t => {
      t.addEventListener("click", this.onTabClick)
      t.setAttribute("aria-selected", t.classList.contains("tab-active"))
    })
  }

  disconnect() {
    this.tabTargets.forEach(t => t.removeEventListener("click", this.onTabClick))
  }

  onTabClick = (evt) => {
    if (evt.target != this.activeTab) {
      this.tabTargets.forEach(t => {
        // true if "tab-active" included in list, otherwise false
        const res = t.classList.toggle("tab-active")
        t.setAttribute("aria-selected", res)
      })

      this.contentTargets.forEach(c => c.classList.toggle("hidden"))
    }
  }
}
