import SaController from './sa_controller';
import { debounce } from 'lodash';

export default class extends SaController {
  connect() {
    this.debounceChanged = debounce(this.changed, 200); 
    this.element.addEventListener('change', this.debounceChanged);
  }

  changed = (event) => {
    document.getElementById(this.frame).setAttribute('src', this.path + "?" + this.data)
  }

  get data() {
    const formData = new FormData(this.element);
    const data = [...formData.entries()];
    const asString = data
      .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
      .join('&');
    return asString;
  }

  get frame() {
    return this.element.dataset.frameId;
  }

  get path() {
    return this.element.dataset.framePath;
  }

  disconnect() {
    this.element.removeEventListener('change', this.changed);
  }
}



