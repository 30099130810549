import SaController from './sa_controller';
import Highcharts from 'highcharts';


export default class extends SaController {
  static values = {
    message: String
  }

  connect() {
    this.element.setCustomValidity(this.messageValue)
    this.element.reportValidity()
  }

  disconnect() {
  }
}

