import SaController from '../sa_controller';
import { put } from '@rails/request.js'
import { debounce } from 'lodash';

/****************************************************
 Compose section controller
 ***************************************************/
export default class extends SaController {
  static values = {
    valid: Boolean,
    manualSubmit: Boolean
  }
  static targets = ['error'];

  connect() {
    this.element.addEventListener('input', this.onInput);
    this.element.composeFormController = this;
  }

  onInput = debounce(() => {
    if(!this.manualSubmitValue) {
      this.sideSubmit();
    }
  }, 300) 

  async sideSubmit() {
    const data = new FormData(this.element)
    return await this.managerController.sideSubmit(this.element.action, data);
  }

  submit() {
    this.element.requestSubmit();
  }
  
  /************************************/
  /* Only side submit if the focus was completely lost from underneath  calling element*/
  /************************************/
  async sideSubmitIfLostFocus(evt) {
    const currentTarget = evt.currentTarget;
    setTimeout(async() => {
      if(!currentTarget.contains(document.activeElement)) {
        const data = new FormData(this.element)
        return await this.managerController.sideSubmit(this.element.action, data);
      }
    });
  }

  get sectionController() {
    return this.element.closest('[data-controller=compose--section]').sectionController;
  }

  get managerController() {
    return this.element.closest('[data-controller=compose--manager]').composeManager;
  }

  disconnect() {
    this.element.removeEventListener('input', this.onInput);
  }
}
