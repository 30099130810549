import SaController from './sa_controller';
import '@uppy/core/dist/style.css'

import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import ThumbnailGenerator from '@uppy/thumbnail-generator'

export default class extends SaController {
  static targets = ['input', 'list', 'trigger', 'attachments', 'error']
  static values = {
    options: Object,
    presigned: String
  }

  get secured() {
    return this.element.getAttribute('data-secure-uploads') == 'true'
  }

  get uppyOptions() {
    return Object.assign(
      { autoProceed: true },
      this.optionsValue
    )
  }

  get imageTemplate() {
    const template = this.element.querySelector('#uppy-FileInput-list-item-image-template')
    return template.cloneNode(true)
  }

  get nonImageTemplate() {
    const template = this.element.querySelector('#uppy-FileInput-list-item-other-template')
    return template.cloneNode(true)
  }

  connect() {
    this.inputTarget.addEventListener('change', this.onInputTargetChange)
    this.triggerTarget.addEventListener('click', this.onTriggerClicked)

    this.uppy = new Uppy(this.uppyOptions)
    this.log("Options: ", this.uppyOptions)

    this.uppy.use(AwsS3, { getUploadParameters: this.getUploadParameters })
    this.uppy.use(ThumbnailGenerator, { thumbnailWidth: 150, thumbnailHeight: 150 })

    this.uppy.on('restriction-failed', this.onRestrictionFailed)
    this.uppy.on('file-removed', this.uppyFileRemoved)
    this.uppy.on('files-added', this.uppyFilesAdded)
    this.uppy.on('complete', this.uppyComplete)
    this.uppy.on('upload-success', this.uppyUploadSuccess)
    this.uppy.on('upload-progress', this.uppyUploadProgress)
    this.uppy.on('thumbnail:generated', this.uppyThumbnailGenerated)
  }

  disconnect() {
    this.inputTarget.removeEventListener('change', this.onInputTargetChange)
    this.triggerTarget.removeEventListener('click', this.onTriggerClicked)

    Array.from(this.listTarget.querySelectorAll('button'))
         .forEach(b => b.removeEventListener('click', this.onRemoveFile))

    this.uppy.close()
  }

  onRestrictionFailed = (file, error) => {
    // include force=false to force removal of "hidden"
    this.toggleError(false)
    this.errorTarget.querySelector("[data-error-message]").innerText = error.message
  }

  uppyThumbnailGenerated = (file, preview) => {
    this.log('uppyThumbnailGenerated - file', file)
    this.log('uppyThumbnailGenerated - preview', preview)

    let listItem = this.listTarget.querySelector('[data-key="' + file.id + '"]')
    if (listItem) listItem.innerHTML = listItem.innerHTML.replace(/\{data:preview-src\}/gi, preview)
  }

  uppyUploadProgress = (file, progress) => {
    this.log('uppyUploadProgress - file', file);
    this.log('uppyUploadProgress - progress', progress);

    let val = Math.floor(parseFloat(progress.bytesUploaded / progress.bytesTotal) * 100).toString()
    const progressNode = this.listTarget.querySelector('div[data-key="' + file.id + '"] > progress')
    if (progressNode) progressNode.value = val
  }

  uppyUploadSuccess = (file, data) => {
    this.log('uppyUploadSuccess - file', file)
    this.log('uppyUploadSuccess - data', data)

    const container = this.listTarget.querySelector('div[data-key="' + file.id + '"]')

    if (container) {
      container.querySelector('progress').remove()

      let button = container.querySelector('button')
      button?.addEventListener('click', this.onRemoveFile)
      button?.classList.remove('hidden')
    }

    this.updateInternalFileState()
  }

  onRemoveFile = (event) => {
    this.log('onRemoveFile - event', event)
    event.target.removeEventListener('click', this.onRemoveFile)

    const div = event.target.closest('div')
    this.uppy.getFiles()
             .filter(f => f.id == event.target.dataset.key)
             .forEach(f => this.uppy.removeFile(f.id))

    div.remove()
  }

  updateInternalFileState = () => {
    this.attachmentsTarget.value = this.uppy.getFiles().map(f => f.meta.asset.id)
  }

  uppyFilesAdded = (files) => {
    this.log('uppyFilesAdded - files added', files)
    files.forEach(file => {

      let template = file.type.split('/')[0] == 'image' ? this.imageTemplate : this.nonImageTemplate
      template.innerHTML = template.innerHTML.replace(/\{data:file-name\}/gi, file.name)
      template.innerHTML = template.innerHTML.replaceAll(/\{data:file-key\}/gi, file.id)
      this.listTarget.insertAdjacentHTML('beforeend', template.innerHTML)
    })
  }

  uppyFileRemoved = (file, reason) => {
    this.log('uppyFileRemoved - file', file)
    this.updateInternalFileState()
  }

  uppyComplete = (event) => {
    this.inputTarget.value = null
  }
 
  onTriggerClicked = (event) => {
    this.inputTarget.click()
    event.preventDefault();
    event.stopPropagation();
  }

  onInputTargetChange = (event) => {
    const files = Array.from(event.target.files)
    this.log('onInputTargetChange - files', files)

    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          this.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    })
  }

  getUploadParameters = (file) => {
    return  this.post(this.presignedValue, { name: file.name, content_type: file.type, size: file.size, secured: this.secured })
                .then((response) => {
                  return response.json()
                }).then((data) => {

                  this.uppy.setFileMeta(file.id, Object.assign(file.meta, { asset: data.result.asset }))

                  return {
                    method: 'POST',
                    url: data.result.url,
                    fields: data.result.fields,
                    headers: {},
                  }
                })
  }

  log = (message, args) => {
    if (this.uppy?.opts?.debug) {
      console.log(message, args)
    }
  }

  toggleError = (force) => {
    if (this.hasErrorTarget) {
      this.errorTarget.classList.toggle("hidden", force);
    }
  }

// eof
}
