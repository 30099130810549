import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ "engagement", "reach", "follows" ];

  connect() {
    this.fetch_engagement();
    this.fetch_reach();
    this.fetch_follows();

    /* Turn off for now
    setInterval(() => {
      this.fetch_engagement();
      this.fetch_reach();
      this.fetch_follows();
    }, this.timeBetweenUpdates)
    */
  }

  async fetch_engagement() {
    this.engagementTarget.innerText = this.randomNumber
  }

  async fetch_reach() {
    this.reachTarget.innerText = this.randomNumber
    
  }
  
  async fetch_follows() {
    this.followsTarget.innerText = this.randomNumber
  }

  get timeBetweenUpdates () {
    // return 30 * 60 * 1000;
    return 300 * 1000;
  }

  get randomNumber () {
    return Math.floor(Math.random() * (20 - 1 + 1)) + 1;
  }
}
