import SaController from './sa_controller';

export default class extends SaController {
  active(evt) {
    this.element.querySelectorAll("sa-button").forEach(el => el.removeAttribute('open'));
    evt.target.setAttribute("open", true);
  }
}


