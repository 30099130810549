// Just used for loading between tabs when
// turbo is not an option
import SaController from './sa_controller';

export default class extends SaController {
  dim = (event) => {
    const toDim = document.querySelector("#main"); 

    if(!this.dimmer) {
      const throbber = document.createElement("div");
      const dimmer = document.createElement("div");

      dimmer.id = "dimmer";
      dimmer.classList.add('absolute','inset-0','opacity-50','bg-neutral','flex','justify-center','items-center')
      dimmer.style.zIndex = '31'
      throbber.innerHTML = this.throbberSvg;

      dimmer.append(throbber);

      toDim.append(dimmer);
    } else {
      toDim.append(this.dimmer);
    }
  }

  undim = () => {
    this.dimmer.remove();
  }

  get dimmer() {
    return document.querySelector("#dimmer");
  }

  get throbberSvg() {
    return `<svg class="animate-spin h-16 w-16 text-neutral-content" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>`
  }
}
