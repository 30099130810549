import SaController from '../sa_controller';
import {computePosition, autoPlacement, inline, autoUpdate} from '@floating-ui/dom';
//import {html} from 'lit';
import { debounce } from 'lodash';

export default class extends SaController {
  static targets = ['input', 'list', 'selected', 'remoteFrame', 'popup', 'value', 'anchor', 'start'];
  static values = {url: String, opened: Boolean, limit: Number, submitBlank: Boolean};

  initialize() {
    this.debouncedBlurred = debounce(this.blurred, 100);
  }

  connect() {
    this.element.addEventListener('keydown', this.keyHandler);
    this.initialzeByValue();
    this.checkState();
  }

  initialzeByValue() {
    // Initialize selectedTarget and listTarget based on valueTarget
    if(this.urlValue) {
        fetch(this.urlValue + `?id=${this.valueTarget.value}`)
          .then(response => response.text())
          .then(data => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, 'text/html');
            const elements = doc.querySelectorAll(`[data-value]`);
            elements.forEach((element) => {
              const chip = this.wrapInChip(element);
              this.anchorTarget.insertAdjacentElement('beforebegin', chip);
            });
          })
    } else {
      const values = this.valueTarget.value.split(', ');
      values.forEach((value) => {
        // Grab all values and add them to selectedTarget
        const element = this.listTarget.querySelector(`[data-value="${value}"]`);
        
        const chip = this.wrapInChip(element);
        this.anchorTarget.insertAdjacentElement('beforebegin', chip);
      });
      if(this.openedValue) {
        this.listTarget.removeChild(element);
      }
    }
    this.setValues();
  }

  clickOutside = (evt) => {
    console.log('clickOutside', event.target);
    // If click is outside of element (and still exists in the document)
    if (this.element.contains(event.target) || !document.contains(event.target)) {
      console.log('-- inside');
      return;
    } else {
      console.log('-- outside');
      this.startTarget.controller.reset();
      this.close();
    }
  }

  toggleOpen() {
    console.log('toggleOpen');
    this.openedValue = !this.openedValue;
  }

  open() {
    console.log('open');
    this.openedValue = true;
    this.place();
    window.addEventListener("click", this.clickOutside);
  }

  close() {
    console.log('close'); 
    this.openedValue = false;
    window.removeEventListener("click", this.clickOutside);
  }

  styleAsOpened() {
    this.popupTarget.style.display = 'block';
    this.inputTarget.style.display = 'block';
    this.startTarget.style.display = 'none';
    this.showList();
  }

  styleAsClosed() {
    this.startTarget.style.display = 'block';
    this.inputTarget.style.display = 'none';
    this.popupTarget.style.display = 'none';
    this.hideList();
  }

  hideList() {
    this.listTarget.style.display = 'none';
  }

  showList() {
    this.listTarget.style.display = 'block';
    this.place();
  }

  openedValueChanged = (value, prevValue) => {
    console.log('openedValueChanged', value, prevValue);
    if (value) {
      this.styleAsOpened();
      this.inputTarget.focus();
    } else {
      this.styleAsClosed();
      if (prevValue) {
        console.log('Emitting close');
        this.element.dispatchEvent(new CustomEvent('close', {bubbles: true}));
      }
    }
  }

  setValues() {
    // Set values based on selectedTarget items
    const values = Array.from(this.selectedTarget.querySelectorAll('[data-value]')).map((node) => {
      return node.getAttribute('data-value');
    });
    if(values.length === 0 && this.submitBlankValue) {
      this.valueTarget.value = null;
      this.element.dispatchEvent(new CustomEvent('new_value', {bubbles: true}));
    } else if(values.length === 0 && !this.submitBlankValue) {
      this.showList();
    } else {
      if(this.valueTarget.value !== values.join(',')) {
        this.valueTarget.value = values.join(',');
        this.element.dispatchEvent(new CustomEvent('new_value', {bubbles: true}));
      }
    }
  }

  keyHandler = (evt) => {
    // Navigate listTarget with arrow keys
    if (evt.key === 'ArrowDown') {
      evt.preventDefault();
      // See if focus is on input, if so, focus on first list item
      if (document.activeElement === this.inputTarget) {
        if (this.listTarget.children.length > 0) {
          this.listTarget.children[0].focus();
        }
      } else {
        // Focus on next list item
        const index = Array.from(this.listTarget.children).indexOf(document.activeElement);
        if (index < this.listTarget.children.length - 1) {
          this.listTarget.children[index + 1].focus();
        }
      }
    }
    if (evt.key === 'ArrowUp') {
      evt.preventDefault();
      // See if focus is on input, if so, focus on last list item
      if (document.activeElement === this.inputTarget) {
        if (this.listTarget.children.length > 0) {
          this.listTarget.children[this.listTarget.children.length - 1].focus();
        }
      } else {
        // Focus on previous list item
        const index = Array.from(this.listTarget.children).indexOf(document.activeElement);
        if (index > 0) {
          this.listTarget.children[index - 1].focus();
        }
      }
    }
    if (evt.key === 'Enter') {
      evt.preventDefault();
      if (document.activeElement === this.inputTarget) {
        if (this.listTarget.children.length > 0) {
          this.listTarget.children[0].focus();
        }
      } else {
        document.activeElement.click();
      }
    }
    if (evt.key === 'Escape') {
      evt.preventDefault();
      this.inputTarget.focus();
    }
    if (evt.key === 'Backspace') {
      // if input is empty, remove last chip
      if (this.inputTarget.value === '') {
        evt.preventDefault();
        this.selectedTarget.children[this.selectedTarget.children.length - 2].lastChild.click()
      }
    }
  }

  place() {
    // TODO: W/o autoUpdate the popup will stay fixed during scroll, but with it, the popup will move with the anchor, i.e., moving target
    autoUpdate(this.anchorTarget, this.popupTarget, () => {
      computePosition(this.anchorTarget, this.popupTarget, {
        placement: 'bottom-end', 
      }).then(({x, y}) => {
        Object.assign(this.popupTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    });
  }

  focus() {
    this.fetchData();
  }

  toggle = (evt) => {
    console.log('toggle');
    const element = evt.currentTarget;
    if (element.parentElement === this.listTarget) {
      this.select(evt);
    } else {
      this.remove(evt);
    }
  }

  remove = (element) => {
    console.log('remove');
    // Remove element from selectedTarget
    // unwrap element from chip and add it to listTarget
    const chip = element.parentElement;
    this.inputTarget.focus();
    this.selectedTarget.removeChild(chip);
    chip.removeChild(element);
    this.listTarget.appendChild(element);
    this.checkState();

    setTimeout(() => {
      this.open();
      this.inputTarget.focus();
    });

    if(!this.openedValue && this.submitBlankValue) {
      this.element.dispatchEvent(new CustomEvent('close', {bubbles: true}));
    }
  }

  select = async(evt) => {
    this.inputTarget.focus();
    // Select element from listTarget and add it to the selectedTarget
    const element = evt.currentTarget;

    element.style.viewTransitionName = "card-active";

    const transition = document.startViewTransition(() => {
      this.listTarget.removeChild(element);
      const chip = this.wrapInChip(element)
      this.anchorTarget.insertAdjacentElement('beforebegin', chip);
    });

    // Wait for the animation to complete.
    await transition.finished;
    element.style.viewTransitionName = "none";
    this.inputTarget.value = '';

    this.checkState();
  }

  wrapInChip(element) {
    // Wrap element in a chip with remove element
    const chip = document.createElement('div');
    chip.classList.add('flex', 'flex-row', 'bg-base-200', 'rounded-full', 'border', 'border-gray-300', 'justify-center', 'items-center', 'inline-flex', "pl-2");
    chip.appendChild(element);
    const remove = document.createElement('button');
    remove.innerHTML = this.closeIcon();
    remove.classList.add('mr-2');
    remove.addEventListener('click', () => { this.remove(element)});
    chip.appendChild(remove);
    return chip;
  }

  blurred = () => {
    console.log('blurred');
    if (this.element.contains(document.activeElement)) {
      return;
    } else {
      this.setValues();
      this.close();
    }
  }

  blur(evt) {
    this.debouncedBlurred();
  }

  checkState() {
    // If listTarget is empty, hide it
    if (this.listTarget.children.length === 0) {
      this.hideList();
    } else if (this.openedValue) {
      this.showList();
    }

    const values = Array.from(this.selectedTarget.querySelectorAll('[data-value]')).map((node) => {
      return node.getAttribute('data-value');
    });

    if(this.limitValue > 0 && values.length >= this.limitValue) {
      this.startTarget.style.display = 'none';
      this.anchorTarget.style.display = 'none';
    } else {
      this.startTarget.style.display = 'block';
      this.anchorTarget.style.display = 'flex';
    }

    // sort all list items in listTarget and set in dom
    const items = Array.from(this.listTarget.children).sort((a, b) => {
      return a.textContent.localeCompare(b.textContent);
    });
    this.listTarget.innerHTML = '';
    items.forEach((item) => {
      this.listTarget.appendChild(item);
    });

    this.setValues();
  }

  fetchData = () => {
    if(this.hasRemoteFrameTarget) {
      // Fetch html and set listTarget to it
      //this.remoteFrameTarget.src = this.urlValue;
      this.remoteFrameTarget.src = this.urlValue + `?search=${this.inputTarget.value}&exclude=${this.valueTarget.value.split(',')}`;
    }
  }

  filter = () => {
    if(this.urlValue) {
      this.fetchData();
    } else {
      this.listTarget.children.forEach((node) => {
        if (node.textContent.includes(this.inputTarget.value)) {
          node.style.display = 'block';
        } else {
          node.style.display = 'none';
        }
      })
    }
  }

  closeIcon = () => {
    const html = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" size="sm" class="w-4 h-4 fill-current"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>`
    const node = new DOMParser().parseFromString(html, "text/html").body.firstElementChild;

    return html;
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.keyHandler);
    window.removeEventListener("click", this.clickOutside);
  }

}
