import SaController from './sa_controller';

export default class extends SaController {
  static targets = ['main', 'save', 'updateConnection', 'channels', 'header', 'footer'];

  linkConnection(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.connectionWindow = window.open("/connect/channel/facebook?instagram=true&send_to_opener=true&scope_to_existing=true&auto_connect=true",'_blank','height=480,width=640,top=200,left=200,status=no,location=no,toolbar=no,menubar=no,directories=no,resizable=yes,scrollbars=yes',false);
    this.connectionWindow.focus();

    window.populateAccounts = this.accounts.bind(this);
  }

  async save() {
    const vals = await this.channelsTarget.state();
    const res = await this.post("/connect/instagram", vals.json);
    if(!res.ok) {
      this.mainTarget.innerHTML = `
          <sa-message>An error occurred updating your Instagram Channels. Please try again or contact support.</sa-message>
        `;
    } 
    this.element.closest("sa-modal").open = false;
  }

  statusIcon(account) {
    if(account.connected) {
      return `<sa-icon class="text-green-5" name="check-circle" size="large"></sa-icon>`;
    } else {
      return `<sa-icon class="text-orange-6" name="warning" size="large"></sa-icon>`;
    }
  }

  channelLabel(account) {
    return `
    <div class="flex items-center">
      ${ this.statusIcon(account) }
      <sa-avatar class="mx-4" shape="circle" size="medium" src="${ account.profile_picture_url }"></sa-avatar> 
      <div>
        <sa-label size="large">${ account.name }</sa-label>
        <sa-label>on ${ account.brand_name }</sa-label>
      </div>
    </div>
    `
  }


  accounts(win, data) {
    win.close();
    let html = '';
    const updatedChannels = data.filter(acct => acct.connected);
    const updatedCurrentBrandChannels = data.filter(acct => acct.connected && acct.current_brand);
    const updatedOtherBrandChannels = data.filter(acct => acct.connected && !acct.current_brand);
    const failedChannels = data.filter(acct => !acct.connected);


    let currentBrandChannelLabel = "";
    let otherBrandChannelLabel = "";

    if(updatedOtherBrandChannels.length > 0) {
      otherBrandChannelLabel = updatedCurrentBrandChannels.length === 0 ? "<p class='text-lg'>We were able to update these channels on other brands</p>" : "<p class='text-lg'>We were also able to update these channels on other brands</p>";
    }

    if(updatedCurrentBrandChannels.length > 0) {
      currentBrandChannelLabel = '<p class="text-lg">These channels have been automatically updated</p>'
    }

    if(updatedChannels.length > 0 && failedChannels.length > 0) {
      this.headerTarget.innerText = "Update Partially Complete";
    } else if(updatedChannels.length > 0) {
      this.headerTarget.innerText = "Connections Updated";
    } else if(failedChannels.length > 0) {
      this.headerTarget.innerText = "Unable to updated channels";
    } else {
      this.headerTarget.innerText = "No existing Instagram channels found";
    }


    if(updatedChannels.length > 0) {
      this.footerTarget.innerHTML = `<center><sa-button href="${ window.location.href }" use-pjax="false">Close</sa-button></center>`;
    } else {
      this.footerTarget.innerHTML = `<center><sa-button side-effect="close-modal">Close</sa-button></center>`;
    }

    if(failedChannels.length > 0) {
      html += `<p class="text-lg mb-4">
      It looks like not all of your Business Instagram channels are connected. Please review and try again.
      </p>`;
    }

    if(updatedChannels.length > 0) {
      html += `<div>
        <div class="bg-gray-1 border-gray-2 rounded relative px-4 py-6">
          <div class="absolute right-0 left-0" style="top: -0.75rem">
            <sa-tag class="m-auto" pill color="green" style="width: 6.5rem" shade="6">Success</sa-tag>
          </div>
          <sa-grid noresponsive cols="1">
            ${currentBrandChannelLabel}
            ${updatedCurrentBrandChannels.map(this.channelLabel.bind(this)).join('\n')}
            ${otherBrandChannelLabel}
            ${updatedOtherBrandChannels.map(this.channelLabel.bind(this)).join('\n')}
          </sa-grid>
        </div>
      </div>`
    }

    if(failedChannels.length > 0) {
      html += `<div class="mt-8">
        <div class="bg-gray-1 border-gray-2 rounded relative px-4 py-6">
          <div class="absolute right-0 left-0" style="top: -0.75rem">
            <sa-tag class="m-auto" pill color="orange" style="width: 12.5rem" shade="6">Unable to Update</sa-tag>
          </div>
          <p class="text-lg mb-4">
          These channels could not be updated
          </p>
          <sa-grid noresponsive cols="1" class="mb-4">
            ${failedChannels.map(this.channelLabel.bind(this)).join('\n')}
          </sa-grid>
          <p class="text-lg">
          It looks like you're unable to connect your Business Instagram account. Let's figure out how to help!
          </p>
          <p class="text-lg">
          1. Is the Business Instagram account connected to a Facebook page?<br/>
          2. Do you have admin privileges the Facebook page the Instagram account is connected to?
          </p>
          <p class="text-lg">
          Still need help? <a href="#">Contact Support.</a> 
          </p>
        </div>
      </div>`;
    }

    this.mainTarget.innerHTML = `<sa-form data-update-instagram-target="channels">
      <sa-grid noresponsive cols="1">
        ${html}
      </sa-grid>
    <sa-form>`
  }
}
