import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.addEventListener('click', this.click.bind(this));
  }

  disconnect() {
  }

  click(e) {
    e.preventDefault();
    document.querySelector(this.element.dataset.selector).click();
    this.element.blur();
  }
}
