import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['save', 'length', 'upper', 'lower', 'number', 'special', 'match', 'password', 'confirmPassword'];

  connect() {
  }

  get password() {
    return this.passwordTarget.value;
  }

  get confirmPassword() {
    return this.confirmPasswordTarget.value;
  }

  check() {
    if([
      this.errorCheck(this.lengthTarget,  (val) => val.length >= 8),
      this.errorCheck(this.upperTarget,   (val) => /[A-Z]/.test(val)),
      this.errorCheck(this.lowerTarget,   (val) => /[a-z]/.test(val)),
      this.errorCheck(this.numberTarget,  (val) => /\d/.test(val)),
      this.errorCheck(this.specialTarget, (val) => /[\!\@\#\$\%\^\*\(\)\{\}\[\]\-\_\=\+\:\;\,\.\>\<]/.test(val)),
      this.errorCheck(this.matchTarget,   (val) => val === this.confirmPassword)
    ].every(val => val)) {
      console.log("YAY");
      this.saveTarget.classList.remove('btn-disabled');
    }
  }

  errorCheck(target, check) {
    if(check(this.password)) {
      target.classList.remove("error");
      target.classList.add("success");
      return true;
    } else {
      target.classList.add("error");
      target.classList.remove("success");
      return false;
    }
  }
}

