import SaController from './sa_controller';

export default class extends SaController {
  static targets = [ 'highlightable' ]
  static values = {
    'keywords': Array
  }

  connect() {
    this.marks = [];
    this.highlightableTargets.forEach(target => {
      const nodes = this.getTextNodesIn(target);
      nodes.forEach(node => {
        const parent = node.parentElement;
        this.keywordsValue.forEach(keyword => {
          const idx = this.indexOfWord(keyword, node.textContent);
          if(idx >= 0) {
            const word = node.splitText(idx);
            const after = word.splitText(keyword.length);
            const mark = document.createElement('span');
            mark.classList.add('font-bold', 'text-red-700', 'underline');
            mark.appendChild(word);
            this.marks.push(mark);
            parent.insertBefore(mark, after);
          }
        });
      });
    });
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  get keywordRegexes() {
    return this.keywordsValue.map(keyword => new RegExp(`\\b${this.escapeRegExp(keyword)}\\b`, 'i'))
  }

  indexOfWord(word, text) {
    const reg = new RegExp(`\\b${word}\\b`, 'i');
    return reg.exec(text)?.index;
  }

  show() {
    this.marks.forEach(x => {
      x.classList.add('text-lg');
    });
  }

  hide() {
    this.marks.forEach(x => {
      x.classList.remove('text-lg');
    });
  }

  getTextNodesIn(node, includeWhitespaceNodes) {
    var textNodes = [], whitespace = /^\s*$/;

    function getTextNodes(node) {
        if (node.nodeType == 3) {
            if (includeWhitespaceNodes || !whitespace.test(node.nodeValue)) {
                textNodes.push(node);
            }
        } else {
            for (var i = 0, len = node.childNodes.length; i < len; ++i) {
                getTextNodes(node.childNodes[i]);
            }
        }
    }

    getTextNodes(node);
    return textNodes;
  }

}
