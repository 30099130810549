import { put } from '@rails/request.js'
import { debounce, omit } from 'lodash';
import ComposeController from '../compose_controller'

/****************************************************
 Compose section controller
 ***************************************************/
export default class extends ComposeController {
  static values = {
    valid: Boolean
  }
  static targets = ['error','file'];

  connect() {
    this.element.sectionController = this;
  }

  get valid() {
    return !this.hasErrorTarget;
  }

  get processing() {
    return this.element.getAttribute('processing');
  }

  get key() {
    return this.element.getAttribute('key');
  }

  get state() {
    const form = this.element.querySelector("form")  
    let value = null;
    if(form) { value = this.formToObject(form) }
    return value;
  }

  committing() {
    // No-op override if you want to do something when a section is committing
  }

  formToObject(form) {
    let object = {};
    const formData = new FormData(form);
    formData.forEach((value, key) => {
        const normalized_key = key.endsWith("[]") ? key.slice(0,-2) : key
        
        // Reflect.has in favor of: object.hasOwnProperty(key)
        if(!Reflect.has(object, normalized_key)){
            object[normalized_key] = value;
            return;
        }
        if(!Array.isArray(object[normalized_key])){
            object[normalized_key] = [object[normalized_key]];    
        }
        object[normalized_key].push(value);
    });
    return omit(object, ['utf8','_method','authenticity_token']);
  }

  async sideSubmit(action, data) {
    this.state;
    console.log("Start Section Submit");
    this.startProcessing()
    const response = await this.managerController.sideSubmit(action, data);
    this.endProcessing();
    console.log("End Section Submit");
    return response;
  }

  get managerController() {
    return this.element.closest('[data-controller=compose--manager]').composeManager;
  }

  uploadFile = (event) => {
    this.fileTarget.click();
  }

  createAssets = (event) => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      this.composeManager.requestAsset(file, asset => this.takeAction(
        event.params.assetComponent, 
        'attach_asset', 
        Object.assign(event.params.extra, { asset_id: asset.id, attachment_id: asset.attachment_id })
      ))
    })
  }
}
