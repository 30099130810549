import SaController from './sa_controller';
import { post } from '@rails/request.js'

export default class extends SaController {
  static targets = ['editable', 'editArea', 'form', 'selectableArray'];
  static values = { selected: Array, url: String };

  connect() {
    this.element.addEventListener("click", this.closeEditors);
    this.element.selectableTableController = this;
  }

  highlight(cell) {
    if(this.currentHighlight) {
      this.currentHighlight.highlightValue = false;
    } 
    this.currentHighlight = cell;
    this.currentHighlight.highlightValue = true;
  }

  closeEditors(event) {
    if(event.target.closest('.editing')) {
      return;
    }

    const closeEditorEvent = new CustomEvent("cell-edit:closeother", {detail: {id: 'table'}});
    window.dispatchEvent(closeEditorEvent);
  }

  selectedValueChanged() {
    if(this.selectedValue.length > 0) {
      this.editableTargets.forEach((editable) => {
        this.indicate(editable)
      })
    } else {
      this.editableTargets.forEach((editable) => {
        this.removeIndicate(editable)
      })
    }
  }

  select(event) {
    const id = event.params.id;

    if(this.selectedValue.includes(id)) {
      this.selectedValue = this.selectedValue.filter((id1) => id1 !== id);
      document.getElementById(`${id}_selected`).classList.add('hidden');
      document.getElementById(`${id}_unselected`).classList.remove('hidden');
    } else {
      this.selectedValue = [...this.selectedValue, id];
      document.getElementById(`${id}_selected`).classList.remove('hidden');
      document.getElementById(`${id}_unselected`).classList.add('hidden');
    }
  }

  takeAction(event) {
    const action = event.params.action;
    if(action === 'assign') {
      const userId = event.params.userId;
      post(this.urlValue, {
        body: JSON.stringify({ take_action: action, ids: this.selectedValue, user_id: userId }),
        contentType: 'application/json',
        responseKind: "turbo-stream"})
      this.selectedValue = [];
    }
  }

  indicate(editable) {
    editable.classList.add('editable')
  }

  removeIndicate(editable) {
    editable.classList.remove('editable')
  }

  submit(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    const formData = new FormData(form);
    this.selectedValue.forEach((id) => {
      formData.append('ids[]', id);
    });
    const url = this.urlValue;
    post(url, {
      body: formData,
      responseKind: 'turbo-stream'
    });
    this.selectedValue = [];
  }
}
