import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.addEventListener('change', this.changed);
  }

  changed = () => {
    // Would like to move off of jQuery
    this.request(this.method, this.url);
  }

  get data() {
    return [...this.element.querySelectorAll("input")]
      .map(input => `${encodeURIComponent(input.name)}=${encodeURIComponent(input.value)}`)
      .join('&');
  }

  get url() {
    return this.action + "?" + this.data; 
  }

  get method() {
    return this.element.dataset.method || 'post';
  }

  get action() {
    return this.element.dataset.action;
  }
}
