import SaController from '../sa_controller';
import { get } from '@rails/request.js';
import { debounce } from 'lodash';

export default class extends SaController {
  static targets = ['date', 'shade', 'icon', 'close'];
  static outlets = ["optimize-time"];
  static values = { 
    optimizePath: String,
    requestOptimize: Boolean
  }

  connect() {
    this.requestOptimizeValueChanged();
  }

  requestOptimizeValueChanged() {
    if(this.requestOptimizeValue) {
      this.requestOptimizeValue = false;
      this.dateTarget.classList.add('ring-1', 'ring', 'animate-pulse', 'ring-offset-4');
      this.dateTarget.classList.add('animate-pulse');

      this.timeout = setTimeout(() => {
        get(this.optimizePathValue, {responseKind: 'turbo-stream'}).then((response) => {
          this.closeTarget.classList.remove('hidden');
        });
      }, 2000);
    }
  }


  autoScheduleInEdit() {
    this.optimizeTimeOutlet.setOptimize();
  }


  disconnect() {
    clearTimeout(this.timeout);
  }
}

