import SaController from './sa_controller';

export default class extends SaController {
  static targets = [ 'progress', 'share', 'download', 'done', 'media' ]
  static values = {
    'caption': String,
    'video': Boolean,
    'media': Array,
    'progress': Number
  }

  connect() {
    this.shareTarget.classList.add("hidden");
    this.doneTarget.classList.add("hidden");
    this.downloadInfo = {};
  }

  disconnect() {
  }


  updateMainProgressBar() {
    let received = 0;
    let total = 0; 

    Object.keys(this.downloadInfo).forEach((key) => {
      received += this.downloadInfo[key][0]
      total += this.downloadInfo[key][1]
    });

    this.progressValue = received/total;
  }


  progressValueChanged() {
    this.progressTarget.setAttribute('value', this.progressValue*100)
  }

  progress(evt) {
    this.updateMainProgressBar();
    this.downloadInfo[evt.detail.id] = [evt.detail.received, evt.detail.total];
  }

  complete(evt) {
    this.downloadInfo[evt.detail.id] = [1,1];
    this.updateMainProgressBar();
    this.mediaTargets.find(m => m.id == evt.detail.id).classList.add("ring", "completed")
    this.checkShareReady();
  }

  share() {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: "shareInstagram" }));
    this.shareTarget.classList.add("hidden")
    this.doneTarget.classList.remove("hidden")
  }

  checkShareReady() {
    if(!this.mediaTargets.find(m => !m.classList.contains("completed"))) {
      this.downloadTarget.classList.add("hidden");
      this.shareTarget.classList.remove("hidden");
    }
  }

  downloadImages() {
    window.ReactNativeWebView.postMessage(JSON.stringify({action: "readyInstagram", 
      caption: this.captionValue,
      type: this.videoValue ? "video" : "image",
      media: this.mediaValue}))
  }
}

