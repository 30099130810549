import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ "count" ];

  async connect() {
    if(window.cable) {
      this.subscription = await cable.subscribeTo(this.notificationChannel, { 
        received: this.updateNotifications.bind(this)
      })
    }
  }

  get notificationStream() {
    return this.element.querySelector("turbo-cable-stream-source");
  }

  get notificationChannel() {
    return this.notificationStream.channel;
  }

  clear() {
    this.countTargets.forEach(target => target.classList.add('invisible'));
    this.countTargets.forEach(target => target.innerText = '0');
  }

  updateNotifications(evt) {
    this.countTarget.classList.remove('invisible');
    const inner = this.countTarget.innerText;
    
    let count = 1;
    try {
      count = parseInt(inner) + 1;
    } catch {
    }

    this.countTarget.innerText = count.toString();
  }

  disconnect() {
    this.subscription.unsubscribe();
  }
}




