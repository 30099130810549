import ComposeController from '../compose_controller';
import '../../elements/progress_ring';
import '../../elements/networks_list';
import '../../elements/form_debug';

export default class extends ComposeController {
  static targets = ['file', 'asset', 'progress', 'image', 'editCheckbox'];
  static values = {
    id: String,
    url: String,
    name: String,
    progress: Number,
    converting: {type: Boolean, default: false}
  }

  connect() {
    this.element.assetController = this;
    this.composeManager.element.addEventListener('upload_progress', this.onProgress);
    this.composeManager.element.addEventListener('upload_success', this.onSuccess);
    this.composeManager.element.addEventListener('upload_fail', this.onFail);
  }

  disconnect() {
    this.composeManager.element.removeEventListener('upload_progress', this.onProgress);
    this.composeManager.element.removeEventListener('upload_success', this.onSuccess);
    this.composeManager.element.removeEventListener('upload_fail', this.onFail);
    clearInterval(this.refreshInterval)
  }

  convertingValueChanged() {
    if(this.convertingValue) {
      // Would be nice for this to just be a broadcast from the server
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(this.requestUpdate, 10000)
    } else {
      clearInterval(this.refreshInterval)
    }
  }

  modifyAsset() {
    this.composeManager.editImage(this.idValue, this.nameValue, this.urlValue);
  }

  editAsset() {
    this.editCheckboxTarget.checked = !this.editCheckboxTarget.checked;
    if(!this.editCheckboxTarget.checked) {
      this.requestUpdate();
    }
  }

  state() {
    return {
      id: this.idValue,
      name: this.nameValue
      // TODO ALT TEXT
    };
  }

  requestUpdate = () => {
    if(this.composeManager) {
      this.takeAction('media', 'refresh_asset', {asset_id: this.idValue});
    } else {
      clearInterval(this.refreshInterval)
    }
  }

  setPreview(file) {
    const url = URL.createObjectURL(file);
    if(this.hasImageTarget) {
      this.imageTarget.src = url;
    }
  }

  onFail = (event) => {
    if(this.idValue === event.detail.asset.id) {
      this.progressValue = null;
    }
  }

  onSuccess = (event) => {
    if(this.idValue === event.detail.asset.id) {
      this.progressValue = 100;
    }
  }

  onProgress = (event) => {
    if(this.idValue === event.detail.asset.id) {
      this.progressValue = event.detail.progress*100;
    }
  }

  progressValueChanged(value) {
    this.progressTarget.classList.remove('hidden');
    this.progressTarget.setAttribute('progress', value);
    if(value == 100) {
      this.progressTarget.classList.add('hidden');
    } 
  }

  get uploading() {
    console.log("PROGRESS", this.progressValue);
    return this.progressValue ? this.progressValue != 100 : false;
  }
}
