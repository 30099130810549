import SaController from '../sa_controller';

export default class extends SaController {
  static targets = ["item"]
  static values = {
    role: String
  }

  get ariaCurrentValues () {
    return ["page", "step", "location", "date", "time", "true", true]
  }

  connect() {
    if (this.hasItemTarget) {
      this.itemTargets.forEach(x => x.addEventListener("click", this.clearSelected))
    }
  }

  disconnect() {
    if (this.hasItemTarget) {
      this.itemTargets.forEach(x => x.removeEventListener("click", this.clearSelected))
    }
  }

  clearSelected = (e) => {
    this.itemTargets
      .filter(item => this.ariaCurrentValues.includes(item.ariaCurrent))
      .forEach(x => x.setAttribute('aria-current', false))

    e.target.closest(".sa-aria-current-toggler").ariaCurrent = this.roleValue || "page"
  }
}
