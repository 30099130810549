import SaController from './../sa_controller';

export default class extends SaController {
  static targets = [ 'previewarea', 'previewselect' ];
  static values = {
    closed: String,
    open: String
  }

  preview = (evt) => {
    this.previewselectTarget.classList.remove(...this.closedValue.split(" "))
    this.previewselectTarget.classList.add(...this.openValue.split(" "))

    this.previewareaTarget.classList.remove('hidden')
    
    const frame = this.previewareaTarget.querySelector('turbo-frame')
    frame.src = evt.target.dataset.url
    frame.reload()

    this.previousCard = evt.target.closest('.card')
    this.previousCard?.scrollIntoView(true)
  }

  closePreview = (evt) => {
    this.previewselectTarget.classList.remove(...this.openValue.split(" "))
    this.previewselectTarget.classList.add(...this.closedValue.split(" "))

    this.previewareaTarget.classList.add('hidden')
    const frame = this.previewareaTarget.querySelector('turbo-frame')
    frame.innerHTML = "<span class='mx-auto'>Loading</span>"
    
    this.previousCard?.scrollIntoView(true)
  }

}
