class FormDebug extends HTMLElement {
  constructor() {
    super();

    this.form.addEventListener('input', this.onInput.bind(this)); 
    this.form.addEventListener('change', this.onInput.bind(this)); 
  }

  onInput(event) {
    const formData = new FormData(this.form);
    var object = {};
    formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(object, key)){
          object[key] = value;
          return;
      }
      if(!Array.isArray(object[key])){
          object[key] = [object[key]];    
      }
      object[key].push(value);
    });
    var json = JSON.stringify(object, null, 2);
    this.innerHTML = `<pre>${json}</pre>`;
  }

  get form() {
    return document.getElementById(this.getAttribute('for'));
  }
}

window.customElements.define('form-debug', FormDebug);
