import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ "selected", "calendar" ];
  static values = { url: String }

  connect() {
    this.selectedTarget.addEventListener("update", (e) => {
      let url = this.urlValue;
      url = url + e.detail.map((cal, index) => `${index === 0 ? "?": "&"}calendars[]=${cal}`).join("");
      this.calendarTarget.setAttribute("url", url);
      this.calendarTarget.url = url
    });
  }

}