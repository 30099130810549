import SaController from "./sa_controller";

export default class extends SaController {
  static targets = ["steps", "back", "continue", "button"]
  
  get maxStep(){
    return this.steps.map(s => parseInt(s.dataset.step)).reduce((a, b) => Math.max(a, b), -Infinity)
  }

  get minStep() {
    return this.steps.map(s => parseInt(s.dataset.step)).reduce((a, b) => Math.min(a, b), Infinity)
  }
  
  get steps() {
    return Array.from(this.stepsTarget.querySelectorAll("li"))
  }

  get activeStep () {
    return this.steps.filter(s => s.getAttribute("aria-selected") == "true")[0]
  }

  connect() {
    this.continueTargets.forEach(btn => btn.addEventListener("click", this.onStepContinue))
    this.backTarget.addEventListener("click", this.onStepBack)

    this.updateActionButtons(1)
  }

  disconnect() {
    this.continueTargets.forEach(btn => btn.removeEventListener("click", this.onStepContinue))
    this.backTarget.removeEventListener("click", this.onStepBack)
  }

  onStepContinue = (evt) => {
    evt.preventDefault()
    if (!evt.isTrusted) {
      return
    }

    if (evt.target.dataset.confirm) {
      if(!window.confirm(evt.target.dataset.confirm)) {
        event.preventDefault()
        return
      }
    }

    const submit = evt.target.hasAttribute("data-submit")
    let valid = true
    if (submit && evt.target.form) {
      valid = evt.target.form.reportValidity()
    }

    if (valid) {
      const currentStep = this.activeStep
      const nextKey = parseInt(currentStep.getAttribute("data-step")) + 1
      const next = this.steps.filter(s => parseInt(s.getAttribute("data-step")) == nextKey)

      currentStep.setAttribute("aria-selected", false)
      this.toggleContent(currentStep, true)
      this.updateActionButtons(nextKey)

      next.forEach(s => {
        s.classList.toggle("step-primary", true)
        s.setAttribute("aria-selected", true)

        this.toggleContent(s, false)
      })

      if (submit) {
        evt.target.form.requestSubmit()
      }
    }
  }

  onStepBack = (evt) => {
    evt.preventDefault()

    const currentStep = this.activeStep
    const prevKey = parseInt(currentStep.getAttribute("data-step")) - 1
    const prev = this.steps.filter(s => parseInt(s.getAttribute("data-step")) == prevKey)

    currentStep.setAttribute("aria-selected", false)
    currentStep.classList.toggle("step-primary", false)
    this.toggleContent(currentStep, true)
    this.updateActionButtons(prevKey)

    prev.forEach(s => {
      s.classList.toggle("step-primary", true)
      s.setAttribute("aria-selected", true)

      this.toggleContent(s, false)
    })
  }

  toggleContent = (step, hidden=false) => {
    const controls = this.element.querySelector("#" + step.getAttribute("aria-controls"))
    controls.classList.toggle("hidden", hidden ? true : false)
  }

  updateActionButtons = (step) => {
    this.continueTargets.forEach(btn => {
      const correctStep = btn.hasAttribute("data-show-on-step") ? parseInt(btn.dataset.showOnStep) == step : true
      const show = step != this.maxStep && correctStep
      btn.classList.toggle("hidden", !show)
    })

    const showable = this.backTarget.hasAttribute("data-dont-show-on-step") ? parseInt(this.backTarget.dataset.dontShowOnStep) != step : true
    const show = step != this.minStep && showable
    this.backTarget.classList.toggle("hidden", !show)

    this.buttonTargets.forEach(btn => {
      let showable = false

      if (btn.hasAttribute("data-show-on-step")) {
        showable = parseInt(btn.dataset.showOnStep) == step
      } else if (btn.hasAttribute("data-dont-show-on-step")) {
        showable = parseInt(btn.dataset.dontShowOnStep) != step
      } else {
        showable = true
      }

      btn.classList.toggle("hidden", !showable)
    })
  }
}
