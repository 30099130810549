import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = { url: String }

  connect() {
    console.log("InfiniteScrollController connected")
    this.createObserver()
  }

  loadMore() {
    console.log("Load more")
    if(this.urlValue.length > 0) {
      get(this.urlValue, {responseKind: "turbo-stream"})
    }
  }

  createObserver() {
    const observer = new IntersectionObserver(
      entries => this.handleIntersect(entries),
      {
        threshold: [0, 1],
      }
    )
    observer.observe(this.element);
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }
}
