import SaController from '../sa_controller';
import {computePosition, offset, flip} from '@floating-ui/dom';

// Note that the popup element should have a style of display: none; and classList including "absolute top-0 left-0 z-50" to start

export default class extends SaController {
  static targets = [ 'popup', 'anchor', 'trigger' ]

  connect() {
  }

  disconnect() {
    window.removeEventListener("click", this.checkOutsideClick);
  }

  toggle() {
    if (this.popupTarget.style.display === 'block') {
      this.close();
    } else {
      this.open();
    }
  }

  checkOutsideClick = (event) => {
    if (this.element.contains(event.target)) {
      return;
    } else {
      // Any trigger element must have a controller with a reset method...not ideal
      if (this.hasTriggerTarget && this.triggerTarget.controller !== undefined) {
        this.triggerTarget.controller.reset();
      }
      this.close();
    }
  }

  open() {
    this.popupTarget.style.display = 'block';
    this.place();
    window.addEventListener("click", this.checkOutsideClick);
  }

  close() {
    this.popupTarget.style.display = 'none';
    // emit close event
    const event = new CustomEvent('popup:close', {
      bubbles: true,
      detail: {controller: this}
    });
    this.element.dispatchEvent(event);
    window.removeEventListener("click", this.checkOutsideClick);
  }

  place() {
    computePosition(this.anchorTarget, this.popupTarget, {
      placement: this.placement(),
      middleware: [offset({mainAxis: this.xOffset(), crossAxis: this.yOffset()}), flip()],
    }).then(({x, y}) => {
      Object.assign(this.popupTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  // To pass in offset values, add Data: { x_offset: value, y_offset: value } to the controller element
  xOffset() {
    return parseInt(this.element.dataset.xOffset) || 0
  }

  yOffset() {
    return parseInt(this.element.dataset.yOffset) || 0
  }

  // Placement options include: top, right, bottom, left, and each modified by -start or -end
  placement() {
    return this.element.dataset.placement || 'bottom';
  }

}
