// h-screen does not work on mobile screens so we use the --vh variable instead

function resize() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const initialize = () => {
  resize();
  window.addEventListener('resize', () => { resize(); });
}

export default {
  initialize
}

