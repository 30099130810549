import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['datetime','time', 'date'];

  connect() {
    this.timeChangedCallbacks = [];
    this.dateChangedCallbacks = [];

    this.setDate();
    this.setTime();
  }

  addTimeChangeCallback(callback) {
    this.timeChangedCallbacks.push(callback);
  }

  addDateChangeCallback(callback) {
    this.dateChangedCallbacks.push(callback);
  }

  dateTargetConnected(elem) {
    elem.addEventListener('change', this.dateChanged);
  }

  timeTargetConnected(elem) {
    elem.addEventListener('change', this.timeChanged);
    setTimeout(() => {this.setDatetime()});
  }

  dateTargetDisconnected(elem) {
    elem.removeEventListener('change', this.dateChanged);
  }

  timeTargetDisconnected(elem) {
    elem.removeEventListener('change', this.timeChanged);
  }

  dateChanged = (evt) => {
    this.setDatetime();
    this.dateChangedCallbacks.forEach((callback) => {
      callback(evt);
    })
  }

  timeChanged = (evt) => {
    this.setDatetime();  
    this.timeChangedCallbacks.forEach((callback) => {
      callback(evt);
    })
  }

  disconnect() {
  }

  get datetime() {
    return new Date(this.datetimeTarget.value);
  }

  get visibleTime() {
    return this.timeTargets.find(elem => !this.isHidden(elem))
  }

  setDatetime() {
    if(this.visibleTime.value != '') {
      const build = new Date(`${this.dateTarget.value}T${this.visibleTime.value}`);
      this.datetimeTarget.value = build.toISOString(); 
    }
  }

  setDate() {
    this.dateTarget.value = `${this.datetime.getFullYear()}-${String(this.datetime.getMonth() + 1).padStart(2, '0')}-${String(this.datetime.getDate()).padStart(2, '0')}`
  }


  setTime() {
    this.timeTargets.forEach((elem) => {
      elem.value = this.datetime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hourCycle: 'h23'});
    });
  }

  isHidden(el) {
    var style = window.getComputedStyle(el.parentElement);
    return (style.display === 'none')
  }
}
