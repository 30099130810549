import SaController from './sa_controller';

/*
 * Generic Controller for handling image related issues.
 *
 */
export default class extends SaController {
  notFound({ srcElement }) {
    srcElement.alt = "Image not found";
    srcElement.classList.add("border", "p-4", "text-center", "font-light");
  }
}
