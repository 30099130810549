import SaController from "./sa_controller";
import TinyGesture from 'tinygesture';

export default class extends SaController {
  /*
    We assume that all swipe targets (left, right, etc.) are nav elements with a .click function
  */
  static targets = ["container", "right", "left", "up", "down"]

  /* Pass in options to override the defaults from tinygesture (https://github.com/sciactive/tinygesture) */
  static values = {
    options: {}
  }
  
  connect() {
    this.tg = new TinyGesture(this.containerTarget, this.options)

    this.tg.on("swiperight", this.rightSwipe)
    this.tg.on("swipeleft", this.leftSwipe)
  }

  disconnect() {
    this.tg.destroy()
  }

  get options() {
    return Object.assign({}, this.optionsValue);
  }

  rightSwipe = (event) => {
    if (this.hasRightTarget) {
      this.rightTargets.forEach(elm => elm.click())
    }
  }

  leftSwipe = (event) => {
    if (this.hasLeftTarget) {
      this.leftTargets.forEach(elm => elm.click())
    }
  }
}
