import SaController from './sa_controller';

export default class extends SaController {
  static values = {
    'checkbox': String
  }

  connect() {
    console.log('connected to app remote modal controller')
    this.element.addEventListener('click', this.clicked)
  }

  hide() {
    this.modalCheckbox.checked = false;
  }

  clicked = (event) => {
    if(this.modalCheckbox) {
      this.modalCheckbox.checked = !this.modalCheckbox.checked
    }
  }

  get modalCheckbox() {
    return document.getElementById(this.checkboxValue)
  }

  disconnect() {
    this.element.removeEventListener('click', this.clicked)
  }
}

