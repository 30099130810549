import SaController from '../sa_controller';

export default class extends SaController {
  static targets = ['code'];

  connect() {
    this.element.firstElementChild.focus();
    // Handle backspace 
    this.codeTargets.forEach((el) => {
      el.addEventListener('keydown', (e) => {
        if (e.keyCode === 8 && el.previousElementSibling) {
          el.previousElementSibling.value = '';
          el.previousElementSibling.focus();
        }
      });
    });
    this.codeTargets.forEach((el) => {
      el.addEventListener('focus', this.focus.bind(this)); 
      el.addEventListener('input', this.input.bind(this));
    })
    this.element.addEventListener("paste", this.paste.bind(this));
  }

  firstEmtpyInput() {
    return this.codeTargets.find((el) => el.value === '');
  }

  paste(e) {
    e.preventDefault();
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('text');
    let inputs = this.codeTargets;
    let pastedChars = pastedData.split('');
    let emptyInput = this.firstEmtpyInput();
    let i = 0;

    while (emptyInput && i < pastedChars.length) {
      emptyInput.value = pastedChars[i];
      i++;
      emptyInput = this.firstEmtpyInput();
    }

    // If all inputs are filled
    if (this.firstEmtpyInput() === undefined) {
      this.element.closest('form').submit();
    }
  }

  focus(e) {
    this.firstEmtpyInput().focus();
  }

  input(e) {
    let target = e.target;
    let value = target.value;
    let next = target.nextElementSibling;

    if (value.length === 1 && next) {
      next.focus();
    } else if (value.length === 0 && target.previousElementSibling) {
      target.previousElementSibling.focus();
    }

    if (value.length > 1) {
      target.value = value[0];
    }

    // If all inputs are filled
    if (this.firstEmtpyInput() === undefined) {
      this.element.closest('form').submit();
    }
  }

  disconnect() {
  }
}
