import SaController from '../sa_controller';
import '../../elements/progress_ring';
export default class extends SaController { 
  static targets = ['file', 'presigned'];
  static values = { createUrl: String };

  connect() {
    this.fileTarget.addEventListener('change', this.initiateUpload.bind(this));
    this.files = {};
  }
  presignedTargetConnected = (element) => {
    console.log("PresignedUploadController connected");
    const uploadController = element.presignedUploadController;
    uploadController.startUpload(this.files[uploadController.assetValue.asset.name]);
  }
  
  upload= (event) => {
    this.fileTarget.click();
  }
  initiateUpload = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      const name = file.name.replace("+", "");
      this.files[name] = file;
      this.post(this.createUrlValue,
        { content_type: file.type,
          size: file.size,
          secured: false,
          name: name
        })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    })
    this.fileTarget.value = null;
  }
}
