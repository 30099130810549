import SaController from './sa_controller';

export default class extends SaController {
  static targets = ["network", "connectable"]
  static values = {
    authorizationUrl: String,
    connectUrl: String
  }

  connect() {
    if (this.hasNetworkTarget) {
      this.networkTargets.forEach(t => t.addEventListener("click", this.onNetworkClicked))
    }

    if (this.hasConnectableTarget) {
      this.connectableTargets.forEach(t => t.addEventListener("click", this.onConnectableClicked))
    }
  }

  disconnect() {
    if (this.hasNetworkTarget) {
      this.networkTargets.forEach(t => t.removeEventListener("click", this.onNetworkClicked))
    }

    if (this.hasConnectableTarget) {
      this.connectableTargets.forEach(t => t.removeEventListener("click", this.onConnectableClicked))
    }
  }

  onNetworkClicked = (evt) => {
    const t = evt.target.closest("[data-network]")

    let network = t.dataset.network
    let query = ""

    // Insta goes through the facebook api for connections
    if (network == "instagram") {
      network = "facebook"
      query = "?instagram=true"
    }

    if (network == "facebook_business") {
      network = "facebook"
    }

    if (network == "facebook_other") {
      network = "facebook"
      query = "?non_business=true"
    }

    // TODO: Twitter goes to twitter2 - whenever they are ready for v2 auth
    // if (network == "twitter") {
    //   network = "twitter2"
    // }

    const url = [this.authorizationUrlValue, network + query].join("/")

    this.socialConnectWindow = window.open(url,'_blank','height=480,width=800,top=200,left=200,status=no,location=no,toolbar=no,menubar=no,directories=no,resizable=yes,scrollbars=yes',false);
    this.socialConnectWindow.focus();
  }

  checkInstagramPublishers = (data) => {
    if (data.channel.netwrok == "instagram" && (!data.publisher_users || data.publisher_users?.length == 0)) {
      this.element.querySelector("[data-instagram-publisher-message=\"" + data.channel.external_id + "\"]").classList.toggle("hidden")
    }
  }

  onConnectableClicked = async (evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    const target = evt.target
    const form = evt.target.closest("form")

    let brand_id = undefined
    if (form) {
      if (!form.reportValidity()) {
        return undefined;
      } else {
        let select = form.querySelector("select")
        select.setAttribute("disabled", true)
        brand_id = select.value
      }
    }

    // disable incase of slow connection so we don't double connect
    target.classList.add("btn-disabled")

    let url = [this.connectUrlValue, target.dataset.network, target.dataset.id].join("/")
    if (brand_id) {
      url = [url, "managed_brand_id=" + brand_id].join("?")
    }

    console.debug("onConnectableClicked - Connecting with url:", url)
    const response = await this.post(url)

    if (response.ok) {
      const json = await response.json()
      this.checkInstagramPublishers(json)

      target.classList.remove(["btn-accent", "btn-primary", "btn-error"])
      target.classList.toggle("btn-success")
      target.innerHTML = "Connected!"
    } else {
      target.classList.remove(["btn-success", "btn-accent", "btn-primary"])
      target.classList.toggle("btn-error")
      target.innerHTML = "Error!"
    }

    target.classList.remove("btn-disabled")
  }
}
