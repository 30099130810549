import SaController from './sa_controller';
import TomSelect from 'tom-select';
import { createPopper } from '@popperjs/core';

import 'tom-select/dist/css/tom-select';

export default class extends SaController {
  static targets = ['input', 'label']

  static values = {
    options: Object
  }

  initialize() {
    this.tomSelect = new TomSelect(this.inputTarget, this.selectOptions);
    this.tomSelect.isDisabled = this.selectOptions.ignoreFocus
    this.popper = createPopper(this.tomSelect.control, this.tomSelect.dropdown);
  }

  connect() {
    this.element.multiselectController = this;
    if (this.hasLabelTarget) {    
      this.labelTarget.addEventListener('click', this.focusComponent)
    }

    if(!this.inputTarget.value && this.forcedDefault) {
      this.tomSelect.setValue(this.forcedDefault)
    }

    this.tomSelect.on('item_add', this.onItemAdd);
    this.tomSelect.on('item_remove', this.onItemRemove);
    this.inputTarget.addEventListener('invalid', this.onInvalid);
    this.inputTarget.addEventListener('input', this.clearValidity);
    this.clearValidity();
  }

  disconnect() {
    if (this.hasLabelTarget) {
      this.labelTarget.removeEventListener('click', this.focusComponent)
    }

    this.inputTarget.removeEventListener('invalid', this.onInvalid);
    this.inputTarget.removeEventListener('input', this.clearValidity);
  }

  get forcedDefault() {
    return this.optionsValue.forcedDefault;
  }

  get templateOptionString() {
    const template = this.element.querySelector("#option-template")
    if(template) {
      return template.innerHTML.toString()
    } 
  }

  get templateItemString() {
    const template = this.element.querySelector("#item-template")
    if(template) {
      return template.innerHTML.toString()
    } else {
      return "<div class='badge'>${escape(data.text)}</div>"
    }
  }

  get templateGroupString() {
    const template = this.element.querySelector('#group-template')
    if (template) {
      return template.innerHTML.toString()
    }
  }

  createTemplateFunction(str) {
    return new Function('data', 'escape', `return \`${str}\`;`)
  }

  get render() {
    let renders = {};
    if(this.templateOptionString) {
      renders['option'] = this.createTemplateFunction(this.templateOptionString)
    }

    if(this.templateItemString) {
      renders['item'] = this.createTemplateFunction(this.templateItemString)
    }

    if (this.templateGroupString) {
      renders['optgroup_header'] = this.createTemplateFunction(this.templateGroupString)
    }

    return renders;
  }

  get sortOptions() {
    if(this.optionsValue?.caseInsensativeSort) {
      return { sortField: this.caseInsensativeSort }
    } else {
      return {}
    }
  }

  get remoteOptions() {
    if(this.optionsValue?.remoteUrl) {
      //Fields are easily overrideable by passing them in to the component
      return {
        load: this.load,
        valueField: 'value',
        labelField: 'value',
        searchField: 'value'
      }
    } else {
      return {}
    }
  }

  get pluginOptions() {
    let plugins = []

    if (this.optionsValue.plugins) {
      plugins = plugins.concat(this.optionsValue.plugins)
      return plugins
    } else {
      return { remove_button: { title: 'Remove this item' } }
    }
  }

  get defaultOptions() {
    return { 
      plugins: this.pluginOptions,
      render: this.render,
      onChange: this.onChange,
      shouldLoad: this.shouldLoad,
      onOptionAdd: this.onOptionAdd,
      onFocus: this.onFocusChange,
      onBlur: this.onBlurChange,
      onInitialize: this.onInitialize,
      allowEmptyOption: true,
      maxOptions: 300,
      maxItems: 1,
      minimumQueryLength: 3,
      closeOnSelect: true,
      placeholder: 'Select a value',
      onDropdownOpen: this.onDropdownOpen,
      queryOptions: {
        method: 'POST',
      }
    }
  }

  get selectOptions() {
    return Object.assign(
      this.defaultOptions,
      this.remoteOptions,
      this.sortOptions,
      this.optionsValue
    )
  }

  onChange = (value) => {
    this.dispatchEvent('sa-multiselect-change', value, this.inputTarget.value)
  }

  shouldLoad = (query) => {
    return query.length >= this.selectOptions.minimumQueryLength
  }

  onFocusChange = () => {
    this.tomSelect?.control?.setAttribute('active', true)
  }

  onBlurChange = () => {
    this.tomSelect?.control?.removeAttribute('active')
  }

  onDropdownOpen = () => {
    this.popper.update();
  }

  onItemAdd = (item, element) => {
    if(element.dataset.singleton) {
      this.previousMaxItems = this.tomSelect.settings.maxItems;
      this.tomSelect.setMaxItems(1);
      this.tomSelect.close();
    } else {
      Object.values(this.tomSelect.options).forEach((option) => {
        if(option.singleton) {
          this.tomSelect.removeOption(option.value);
        }
      })
    }
    if(this.forcedDefault) {
      if(this.tomSelect.items.length == 1 && this.tomSelect.items[0] == this.forcedDefault) {
        // No-op
      } else {
        this.tomSelect.removeItem(this.forcedDefault);
      }
    }
  }

  onItemRemove = (item) => {
    if(this.previousMaxItems) {
      this.tomSelect.setMaxItems(this.previousMaxItems);
    }
    if(this.forcedDefault) {
      if(this.tomSelect.items.length == 0) {
        this.tomSelect.setValue(this.forcedDefault);
      }
    }
  }


  onInitialize = () => {
    let _value = this.inputTarget.attributes['value']

    // only preload for remoteUrl selects -- otherwise HTML will do it's thing
    // if it's a standard select with pre-defined options given
    if (this.selectOptions.remoteUrl && _value) {
      this.preload(_value?.value)
    }
  }

  caseInsensativeSort = (left, right) => {
    const left_o = this.tomSelect.options[left.id]
    const right_o = this.tomSelect.options[right.id]

    return left_o.text.localeCompare(right_o.text, undefined, { sensativity: 'base' })
  }

  load = async(query, callback) => {
    var url = `${this.optionsValue.remoteUrl}?search=${encodeURIComponent(query)}`;
    const response = await this.post(url, {});
    const data = await response.json();
    callback(data.results);
  }

  onOptionAdd = (value, data) => {
    if (!this.preloading) {
      this.dispatchEvent('sa-multiselect-option-added', value, value)
    }
  }

  preload = (value) => {
    if (!value) {
      return
    }

    const self = this;
    self.preloading = true
    
    var url = `${self.optionsValue.remoteUrl}?ids=${encodeURIComponent(value)}`;
    self.post(url, {})
      .then(response => response.json())
      .then(q => {
        self.tomSelect.addOptions(q.results, true)
        self.tomSelect.setValue(q.results.map(r => r.value), true)
      })
      .catch((e) => console.error('Error preloading selections', self, self.element, e))
      .finally(() => {
        self.preloading = false
      })
  }

  focusComponent = () => {
    this.tomSelect.focus()
  }

  dispatchEvent = (eventName, value, text) => {
    this.element.dispatchEvent(
      new CustomEvent(
        eventName,
        { 
          bubbles: true, 
          detail: { value: value, text: text }
        }
      )
    )
  }

  clearValidity = (_) => {
    this.inputTarget.setCustomValidity("");
    // Need to clear empty strings so required validity fires
    if(this.inputTarget.value == '') {
      this.inputTarget.value = null;
    }
  }

  onInvalid = (event) => {
    event.target.setCustomValidity("You must make a selection");
  }
}
