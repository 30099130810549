import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ "trigger" ];

  initialize() {
    /*
    this.searchBound = this.search.bind(this);
    this.focusSearchBound = this.focusSearch.bind(this);
    */
  }

  async connect() {
    this.triggerTarget.addEventListener('click', this.click.bind(this));

    const notificationStream = document.querySelector(this.selector + " turbo-cable-stream-source");
    const channel = notificationStream.channel

    if(window.cable) {
      this.subscription = await cable.subscribeTo(channel, { received: this.updateNotifications.bind(this)})
    }
  }

  disconnect() {
    //this.searchTarget.removeEventListener('input', this.searchBound);
  }

  get selector() {
    return "#notification-panel";
  }

  click(e) {
    e.preventDefault();
    document.querySelector(this.selector + " .drawer-toggle").click();
    this.triggerTarget.blur();
  }

  updateNotifications(evt) {
    const counter = this.element.querySelector(".badge");

    const avatar = this.element.querySelector(".avatar");

    avatar.classList.add('online');

    counter.classList.remove('invisible');
    counter.classList.add('visible');

    const inner = counter.innerText;
    let count = 1;
    try {
      count = parseInt(inner)  + 1;
    } catch {
    }
    counter.innerText = count.toString();
  }
}



