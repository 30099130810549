import SaController from './sa_controller';
import { debounce } from 'lodash';

/*
 * Carousel Controller
 */
export default class extends SaController {
  static targets = [ 'back','forward', 'item', 'itemarea' ];
  static values = {
    singleStep: Boolean,
    disableClick: Boolean
  }

  connect() {
    if(this.disableClick) {
      // Disable clicking on the area causing it to close
      this.element.addEventListener('click', this.prevent);
    }

    this.forwardTarget.addEventListener('click', this.forward)
    this.backTarget.addEventListener('click', this.back)
    this.itemareaTarget.addEventListener('scroll', this.updateScrollButtons);
    this.updateScrollButtons();

  }

  back = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.scrollBack();
  }

  forward = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.scrollForward();
  }

  prevent = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  }

  updateScrollButtons = () => {
    if(this.canScrollBack) {
      this.backTarget.classList.remove('hidden');
    } else {
      this.backTarget.classList.add('hidden');
    }
    
    if(this.canScrollForward) {
      this.forwardTarget.classList.remove('hidden');
    } else {
      this.forwardTarget.classList.add('hidden');
    }
  }

  scrollForward() {
    if(this.singleStepValue) {
      this.nextHidden.scrollIntoView({behavior: 'smooth', inline: 'end'})
    } else {
      this.itemareaTarget.scrollBy({ left: this.itemareaTarget.offsetWidth, behavior: 'smooth' });
    }
  }
  
  scrollBack() {
    if(this.singleStepValue) {
      this.prevHidden.scrollIntoView({behavior: 'smooth', inline: 'start'})
    } else {
      this.itemareaTarget.scrollBy({ left: -1*this.itemareaTarget.offsetWidth, behavior: 'smooth' });
    }
  }

  get nextHidden() {
    const scrollRightEdge = this.itemareaTarget.offsetWidth + this.itemareaTarget.scrollLeft;
    return this.itemTargets.find(elem => {
      return (elem.offsetLeft + elem.clientWidth/2) > scrollRightEdge;
    });
  }

  get prevHidden() {
    return this.itemTargets.reverse().find(elem => {
      return elem.offsetLeft <= (this.itemareaTarget.scrollLeft - 10);
    });
  }

  get canScrollForward() {
    // + 10 for a little fudge factor
    const leftPos = this.itemareaTarget.offsetWidth + this.itemareaTarget.scrollLeft + 10;
    return this.canScroll && (leftPos < this.itemareaTarget.scrollWidth);
  }

  get canScrollBack() {
    return this.canScroll && (this.itemareaTarget.scrollLeft > (this.itemTargets[0].offsetLeft + 10))
  }

  get canScroll() {
    return this.itemareaTarget.scrollWidth > this.itemareaTarget.clientWidth;
  }

  disconnect() {
    this.forwardTarget.removeEventListener('click', this.forward)
    this.backTarget.removeEventListener('click', this.back)
    this.itemareaTarget.removeEventListener('scroll', this.updateScrollButtons);
  }
}
