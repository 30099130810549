import SaController from './sa_controller';
import { debounce } from 'lodash';

export default class extends SaController {
  static values = {
    attachedInput: String,
    queryPath: String,
    additionalParams: Object,
    position: String,
    assignedToId: String,
    assignedToType: String,
    subjectId: String,
    subjectType: String,
    currentName: String,
    defaultOpen: Boolean,
    showClear: Boolean
  };
  static targets = ['query', 'frame', 'listbox','clear'];

  connect() {
    this.element.assignmentController = this;

    this.element.tabIndex = 0;
    this.frameId = `frame-${Date.now()}-${this.element.id}`;

    this.element.addEventListener('click', this.clicked);
    this.element.addEventListener('focusout', this.lostFocus);
    this.element.addEventListener('keydown', this.keydown);

    if(!this.currentNameValue) {
      this.element.innerHTML = this.emptyElement;
    }

    if(this.defaultOpenValue) {
      this.beginInteractive();
      setTimeout(() => {
        this.queryTarget.focus();
      });
    }
  }

  disconnect() {
    this.element.removeEventListener('click', this.clicked);
    this.element.removeEventListener('focusout', this.lostFocus);
    this.element.removeEventListener('keydown', this.keydown);
  }

  /* Events */
  keydown = (event) => {
    // Esc - Cancel
    if (event.keyCode === 27) {
      this.removeInteractive();
    }
    // Down arrow - focus on list
    if (event.keyCode === 40) {
      this.listboxTarget.focus();
      event.preventDefault();
    }
    // Space - select
    if (event.keyCode === 32) {
      const selected = this.listboxTarget.querySelector(".focused > a");
      selected.click();
    }
    // Enter - select
    if (event.keyCode === 13) {
      const selected = this.listboxTarget.querySelector(".focused > a");
      selected.click();
    }
  }

  clicked = () => {
    if (!this.interactive) {
      this.beginInteractive();
    }
  }

  lostFocus = (e) => {
    if (this.element.contains(e.relatedTarget) || this.focusing) return;
    this.removeInteractive();
  }

  queryInput = debounce(() => {
    this.frameTarget.src = this.src;
  }, 400);

  /* Actions */
  clearAssignment = (event) => {
    if(this.attachedInput) {
      this.attachedInput.value = null;
      this.element.innerHTML = this.emptyHtml;
      event.preventDefault();
      event.stopPropagation();
      this.interactive = false;
    } else {
      // TODO
    }
  }

  updateAssignment(event) {
    if(this.attachedInput) {
      this.element.innerHTML = event.currentTarget.innerHTML;
      event.preventDefault();
      event.stopPropagation();
      this.attachedInput.value = event.params.id;
      this.attachedInput.setCustomValidity('');
      this.interactive = false;

      var event = new Event('change');
      this.attachedInput.dispatchEvent(event, {bubbles: true});
    } else {
      this.assignToIdValue = event.params["id"]
      this.assignToTypeValue = event.params["type"]

      const payload = {
        assigned_to_id: this.assignToIdValue,
        assigned_to_type: this.assignToTypeValue,
        subject_id: this.subjectIdValue,
        subject_type: this.subjectTypeValue
      }

      this.post("/assignment", payload)
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

  beginInteractive() {
    this.storePrevious();
    this.createInteractive();
    this.interactive = true;
  }

  createInteractive() {
    this.element.innerHTML = `
      <div class="py-1 px-2 w-52 text-sm">
        <input class="border-none outline-none" style="outline: none" data-assignment-target="query" placeholder="${this.currentNameValue}">
        ${ this.clearElement }
      </div>
      <div class="absolute border rounded z-30 bg-base-100 mt-2 ${this.positionClass}">
        <turbo-frame id="${this.frameId}" src="${this.src}" data-assignment-target="frame">
          <div class="w-40 p-2 fade-in">Loading</div>
        </turbo-frame>
      </div>
    `;

    this.focusing = true;
    setTimeout(() => {
      this.queryTarget.addEventListener('input', this.queryInput);
      this.queryTarget.focus();
      this.focusing = false;
      if(this.hasClearTarget) {
        this.clearTarget.addEventListener('click', this.clearAssignment);
      }
    });
  }


  removeInteractive() {
    this.revertPrevious();
    this.interactive = false;
  }

  storePrevious() {
    this.old = this.element.innerHTML;
  }

  revertPrevious() {
    this.element.innerHTML = this.old;
  }

  get clearElement() {
    return this.showClearValue ? '<span class="text-xs cursor-point bold" data-assignment-target="clear">Clear</span>' : '';
  }

  get emptyElement() {
    return '<span class="opacity-60">Choose Assignment</span>'
  }

  get attachedInput() {
    if(this.attachedInputValue) {
      return document.querySelector(this.attachedInputValue)
    } else {
      return '';
    }
  }

  get positionClass() {
    return this.positionValue === 'top' ? 'bottom-[2rem]' : ''
  }

  get src() {
    const url = new URL(`${location.origin}${this.queryPathValue}`);

    const params = url.searchParams;
    params.set('frame_id', this.frameId);
    params.set('query', this.query);

    Object.keys(this.additionalParamsValue).forEach((key) => {
      params.set(key, this.additionalParamsValue[key]);
    });

    url.search = params.toString();
    return url.toString();
  }

  get query() {
    if (this.hasQueryTarget) {
      return this.queryTarget.value;
    } else {
      return "";
    }
  }
}

