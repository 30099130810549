import SaController from '../sa_controller';
import {Turbo} from "@hotwired/turbo-rails"

export default class extends SaController {
  static targets = ['archiveButton', 'viewActivitiesButton', 'queryForm', 'item', 'table'];

  connect() {
    this.queryFormTarget.addEventListener('input', _.debounce(this.query.bind(this), 400));
    this.tableTarget.addEventListener('change', this.itemSelected.bind(this));
  }

  query() {
    this.queryFormTarget.querySelector('input[type=submit]').click();
  }

  itemSelected() {
    if(this.itemTargets.some(x => x.checked)) {
      this.viewActivitiesButtonTarget.classList.remove('hidden');
      this.archiveButtonTarget.classList.remove('hidden');
    } else {
      this.viewActivitiesButtonTarget.classList.add('hidden');
      this.archiveButtonTarget.classList.add('hidden');
    }
  }

  checkedIds() {
    return this.itemTargets.filter(item => item.checked).map(item => item.value)
  }

  viewActivities(evt) {
    const element = evt.target;
    let location = `/admin/content_libraries/${element.dataset.contentLibraryId}/view_channel_activities/${this.checkedIds().join(",")}`;
    Turbo.visit(location);
  }

  archive() {
    this.post("/actions/archive_library_items", {ids: this.checkedIds()});
    this.query();
  }
}

