import SaController from './sa_controller';

export default class extends SaController {
  static targets = [ 'form', 'trigger', 'revealable', 'countable' ]

  static values = {
    'showFilters': Boolean,
    'hasFilters': Boolean,
    'filterCount': Number,
    'url': String
  }

  connect() {
    this.triggerTarget.addEventListener('click', this.triggerClicked)

    if ([undefined, null].includes(this.showFiltersValue)) {
      this.showFiltersValue = true
    }

    this.setup()
  }

  disconnect() {
    this.triggerTarget.removeEventListener('click', this.triggerClicked)
  }

  get changeState() {
    return this.triggerTarget.hasAttribute('data-change-state')
  }

  get tableSortColumn() {
    return this.formTarget.querySelector("#table_column_sort")
  }

  get tableSortDir() {
    return this.formTarget.querySelector("#table_column_dir")
  }

  get showFilterCount() {
    return this.triggerTarget.hasAttribute('data-show-filter-usage-count')
  }

  tableSort = (event) => {
    const target = event.target.nodeName == 'TH' ? event.target : event.target.closest('TH')

    if (this.tableSortDir && this.tableSortColumn && target.dataset.column && target.dataset.dir) {

      const reverseOrder = this.tableSortColumn.value == target.dataset.column
      const asc = target.dataset.dir == 'asc'
      
      this.tableSortColumn.value = target.dataset.column
      this.tableSortDir.value = reverseOrder ? (asc ? 'desc' : 'asc' ) : target.dataset.dir

      this.tableSortColumn.dispatchEvent(new Event('input', { bubbles:true }));
    }
  }

  triggerClicked = (event) => {
    event.preventDefault()

    this.showFiltersValue = !this.showFiltersValue
    this.formTarget.querySelector("input[name='show_filters']").value = this.showFiltersValue

    this.post(this.urlValue, { 'show_filters': this.showFiltersValue })
        .then(r => r.json())
        .then(data => {
          this.hasFiltersValue = data.has_filters
          this.filterCountValue = data.filters_count
        })
        .then(() => this.setup())
  }

  setup = () => {
    this.revealOrHideContent()
    this.changeTriggerState()
    this.changeTriggerInnerText()
  }

  changeTriggerInnerText = () => {
    if (this.showFilterCount) {
      if (!this.showFiltersValue && this.hasFiltersValue) {
        this.triggerTarget.querySelector('span').classList.remove('hidden')
        this.triggerTarget.querySelector('span').innerText = `(${this.filterCountValue})`
      } else {
        this.triggerTarget.querySelector('span').classList.add('hidden')
      }
    }
  }

  changeTriggerState = () => {
    if (this.changeState) {
      if (!this.showFiltersValue && this.hasFiltersValue) {
        this.swapToWarning()
      } else {
        this.swapToPrimary()
      }
    }
  }

  revealOrHideContent = () => {
    this.revealableTargets.forEach(elm => {
      if (this.showFiltersValue) {
        elm.classList.remove('hidden')
      } else {
        elm.classList.add('hidden')
      }
    })
  }

  swapToWarning = () => {
    this.triggerTarget.classList.remove('btn-primary')
    this.triggerTarget.classList.add('btn-warning')
    this.triggerTarget.querySelector('svg').classList.remove('text-primary')
    this.triggerTarget.querySelector('svg').classList.add('text-warning')
  }

  swapToPrimary = () => {
    this.triggerTarget.classList.add('btn-primary')
    this.triggerTarget.classList.remove('btn-warning')
    this.triggerTarget.querySelector('svg').classList.add('text-primary')
    this.triggerTarget.querySelector('svg').classList.remove('text-warning')
  }
}
