import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.addEventListener('turbo:before-fetch-request', this.loading);
    this.checkbox.addEventListener('change', this.clearTurboFrame);
  }

  loading = (event) => {
    if(event.target === this.element) {
      this.showModal();
    }
  }

  clearTurboFrame = (event) => {
    if (!this.checkbox.checked && this.element.innerHTML) {
      this.element.innerHTML = ""
    }
  }

  showModal() {
    this.element.innerHTML = "";
    return this.checkbox.checked = true;
  }

  hideModal() {
    return this.checkbox.checked = false;
  }

  get modal() {
    return this.element.closest('.modal');
  }

  get checkbox() {
    // Hopefully?
    return this.modal.previousElementSibling;
  }

  disconnect() {
    this.element.removeEventListener('turbo:frame-render', this.loading)
    this.checkbox.removeEventListener('change', this.clearTurboFrame);
  }
}
