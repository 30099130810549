import SaController from './sa_controller';

export default class extends SaController {
  connect() {
    this.element.addEventListener('turbo:frame-render', this.frameRendered);
  }

  frameRendered = (event) => {
    if(this.element === event.target) {
      history.pushState({}, '', new URL(event.detail.fetchResponse.response.url))
    }
  }
}
