import SaController from './sa_controller';

export default class extends SaController {

  connect() {
    this.element.addEventListener('change', this.changed)
  }

  disconnect() {
    this.element.removeEventListener('change', this.changed)
  }

  changed = () => {
    this.element.submit()
  }
}
