import SectionController from './section_controller';
import '../../elements/progress_ring';

export default class extends SectionController {
  static targets = ['file', 'asset', 'assetArea'];
  static classes = ['upload'];

  connect() {
    this.element.mediaController = this;
    super.connect();
  }

  disconnect() {
  }

  uploadFile = (event) => {
    this.fileTarget.click();
  }

  imageDimensions = (url) => new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = url;
  })


  get state() {
    return {assets: this.assetTargets.map(assetTarget => assetTarget.assetController?.state())};
  }

  createAssets = () => {
    Array.from(this.fileTarget.files).forEach(async (file) => {
      await this.composeManager.requestAsset(file, asset => this.takeAction(
        this.key,
        'attach_asset', 
        { asset_id: asset.id },
      ))
    })

    // Clear so the same file can be added again
    this.fileTarget.value = '';
  }
}
