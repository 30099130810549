import SectionController from './section_controller';
import '../../elements/progress_ring';

export default class extends SectionController {
  static targets = ['frame'];
  static values = {
    queryPath: String
  }

  connect() {
    super.connect();
    this.element.mentionsController = this;
  }

  setCommand(cmdFn) {
    this.command = cmdFn;
  }

  selectItem(evt) {
    const id = evt.currentTarget.dataset.id;
    const label = evt.currentTarget.dataset.label;
    this.command({id, label});
  }

  keyDown({query, command}){
    this.frameTarget.src = this.queryPathValue + `?query=${query}`;
    return false;
  }
}
