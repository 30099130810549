import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'group', 'reveal', 'hide']
  static values =  { options: Object }

  get parsedOptions() {
    return Object.assign(
      { visibleAmount: 4, showMore: 'Show More', showLess: 'Show Less', forceHide: false, initialClosed: true },
      this.optionsValue
    )
  }

  get groupsExceedVisible() {
    return this.groupTargets.some(g => Array.from(g.querySelectorAll(':scope > [data-revealable]')).length > this.parsedOptions.visibleAmount)
  }

  setup() {
    if (this.hasRevealTarget) {
      this.reveal = this.revealTarget
      this.reveal.addEventListener('click', this.revealClicked.bind(this));
    } else {
      this.reveal = document.createElement('a')
      this.reveal.innerHTML = this.parsedOptions.showMore
      this.reveal.classList = 'link link-primary link-hover text-sm flex items-center'

      if (!this.parsedOptions.initialClosed) this.reveal.classList += ' hidden'
      
      this.reveal.addEventListener('click', this.revealClicked.bind(this));
      this.element.append(this.reveal)
    }

    if (this.hasHideTarget) {
      this.hide = this.hideTarget
      this.hide.addEventListener('click', this.hideClicked.bind(this));
    } else {
      this.hide = document.createElement('a')
      this.hide.innerHTML = this.parsedOptions.showLess
      this.hide.classList = 'link link-primary link-hover text-sm flex items-center'
      
      if (this.parsedOptions.initialClosed) this.hide.classList += ' hidden'

      this.hide.addEventListener('click', this.hideClicked.bind(this));
      this.element.append(this.hide)
    }
  }

  connect() {
    this.setup()

    if (this.parsedOptions.initialClosed) {
      this.hideContent()
    }
  }

  disconnect() {
    this.hide?.removeEventListener('click', this.hideClicked)
    this.reveal?.removeEventListener('click', this.revealClicked)
  }

  hideClicked = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.hideContent()
  }

  hideContent() {
    if (this.groupsExceedVisible || this.parsedOptions.forceHide) {
      this.groupTargets.forEach(g => {
        Array.from(g.querySelectorAll(':scope > [data-revealable]')).forEach((c, index) => {
          if (this.parsedOptions.forceHide || (index > (this.parsedOptions.visibleAmount - 1))) {
            c.classList.add('hidden')
          }
        })

        Array.from(g.querySelectorAll(':scope > [data-revealable-info]')).forEach((c, index) => {
          c.classList.remove('hidden')
        })
      })

      this.reveal.classList.remove('hidden')
      this.hide.classList.add('hidden')
    }
  }

  revealClicked = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.groupTargets.forEach(g => {
      Array.from(g.querySelectorAll(':scope > [data-revealable]')).forEach(c => {
        c.classList.remove('hidden')
      })

      Array.from(g.querySelectorAll(':scope > [data-revealable-info]')).forEach((c, index) => {
        c.classList.add('hidden')
      })
    })

    this.hide.classList.remove('hidden')
    this.reveal.classList.add('hidden')
  }
}
