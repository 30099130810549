import SaController from '../sa_controller';

export default class extends SaController {
  static targets = [ "search", "toggle", "list", "nothing", "focusable", "opener" ]

  initialize() {
    this.searchBound = this.search.bind(this);
    this.focusSearchBound = this.focusSearch.bind(this);
    this.element.addEventListener('keydown', this.keydown);
  }

  connect() {
    this.element.querySelector("[tabindex]").addEventListener('focus', this.focusSearchBound);
    this.searchTarget.addEventListener('input', this.searchBound);
    this.element.addEventListener('keydown', this.keydown);

    // Hack to close dropdown when re-clicking on what opens it
    if(this.hasOpenerTarget) {
      this.openerTarget.addEventListener('mousedown', (event) => { 
        // If focus is in content then we're already opened, so close
        if(this.element.querySelector('.dropdown-content').contains(document.activeElement)) {
          setTimeout(() => document.activeElement.blur());
        }
      });
    }
  }

  disconnect() {
    this.searchTarget.removeEventListener('input', this.searchBound);
  }

  changeBrand(event) {
    this.post(event.target.href, {}).then((response) => {
      if (response.redirected) {
        window.location.href = response.url;
      }
    });
    event.preventDefault();
  }

  keydown = (event) => {
    const focusedIndex = this.visibleFocusableTargets.findIndex(x => x == document.activeElement);
    let newIndex = null;

    if(focusedIndex != -1) {
      if(event.which === 40) {
        newIndex = focusedIndex + 1;
      } else if (event.which === 38) {
        newIndex = focusedIndex - 1;
      }
      if(newIndex >= 0 && newIndex <= this.visibleFocusableTargets.length) {
        this.visibleFocusableTargets[newIndex].focus()
      }
      event.preventDefault();
    }
  }

  get visibleFocusableTargets() {
    return this.focusableTargets.filter(elm => elm.offsetParent);
  }

  search(e) {
    let count = this.listTarget.children.length;

    Array.from(this.listTarget.children).forEach(x => { 
      x.classList.remove('hidden')
      if(!x.textContent.toLowerCase().includes(e.target.value.toLowerCase())) {
        x.classList.add('hidden');
        count -= 1;
      }
    });

    if(count === 0) {
      this.nothingTarget.classList.remove('hidden');
    } else {
      this.nothingTarget.classList.add('hidden');
    }
  }

  focusSearch() {
    this.searchTarget.querySelector('input').focus();
  }
}


