import SaController from './sa_controller';
import '@uppy/core/dist/style.css'

import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

export default class extends SaController {
  static targets = ['avatar', 'input', 'trigger', 'attachment']
  static values = {
    options: Object,
    presigned: String
  }

  get secured() {
    return this.element.getAttribute('data-secure-uploads') == 'true'
  }

  get restrictions() {
    return {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*"]
    }
  }

  get uppyOptions() {
    return Object.assign(
      { autoProceed: true },
      { restrictions: this.restrictions },
      this.optionsValue
    )
  }

  connect() {
    this.inputTarget.addEventListener('change', this.onInputTargetChange)
    this.triggerTarget.addEventListener('click', this.onTriggerClicked)

    this.uppy = new Uppy(this.uppyOptions)
    this.uppy.use(AwsS3, { getUploadParameters: this.getUploadParameters })

    this.uppy.on('complete', this.uppyComplete)
    this.uppy.on('upload-success', this.uppyUploadSuccess)
    this.uppy.on('files-added', this.uppyFilesAdded)
    this.uppy.on('upload-progress', this.uppyUploadProgress)
  }

  disconnect() {
    this.inputTarget.removeEventListener('change', this.onInputTargetChange)
    this.triggerTarget.removeEventListener('click', this.onTriggerClicked)

    this.uppy.close()
  }

  get progressNode() {
    return this.element.querySelector('progress')
  }

  uppyUploadSuccess = (file, data) => {
    this.log('uppyUploadSuccess - file', file)
    this.log('uppyUploadSuccess - data', data)

    this.updateInternalFileState()

    const event = new Event('change', { bubbles: true });
    this.attachmentTarget.dispatchEvent(event);

    this.updateAvatarSrc(file.meta?.asset?.preview)

    this.progressNode?.classList.add('hidden')
  }

  updateInternalFileState = () => {
    this.attachmentTarget.value = this.uppy.getFiles().map(f => f.meta.asset.id)

  }

  updateAvatarSrc = (url) => {
    if (!url) return

    this.avatarTarget.src = url
  }

  uppyComplete = (event) => {
    this.inputTarget.value = null
  }
 
  onTriggerClicked = (event) => {
    this.uppy.cancelAll()
    this.inputTarget.click()
  }

  uppyUploadProgress = (file, progress) => {
    this.log('uppyUploadProgress - file', file);
    this.log('uppyUploadProgress - progress', progress);

    let val = Math.floor(parseFloat(progress.bytesUploaded / progress.bytesTotal) * 100).toString()
    if (this.progressNode) {
      this.progressNode.classList.remove('hidden')
      this.progressNode.value = val
    }
  }

  onInputTargetChange = (event) => {
    event.stopPropagation();
    const files = Array.from(event.target.files)
    this.log('onInputTargetChange - files', files)

    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          this.log('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    })
  }

  getUploadParameters = (file) => {
    return  this.post(this.presignedValue, { name: file.name, content_type: file.type, size: file.size, secured: this.secured })
                .then((response) => {
                  return response.json()
                }).then((data) => {

                  this.uppy.setFileMeta(file.id, Object.assign(file.meta, { asset: data.result.asset }))

                  return {
                    method: 'POST',
                    url: data.result.url,
                    fields: data.result.fields,
                    headers: {},
                  }
                })
  }

  log = (message, args) => {
    if (this.uppy?.opts?.debug) {
      console.log(message, args)
    }
  }

// eof
}
